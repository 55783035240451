import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';

// WNW token buy API
import { greenx } from './greenx-api-web';

import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import WalletCardGridContainer from 'apex-web/lib/components/WalletCards/WalletCardGridContainer';
import WalletRowListContainer from '../components/WalletRows/WalletRowListContainer';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './WalletsPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import TransferRequestNotificationList from 'apex-web/lib/components/TransferRequestsNotification/';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

import Modal from 'apex-web/lib/components/common/Modal/Modal';

import logo_wenew from '../pages/img/wenew-logo.png';

const layoutClasses = getBEMClasses('wallets-page');

const detailsLink = '/wallets/product-details';

const WalletsPage = (props, context) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openPurchaseResultModal, setOpenPurchaseResultModal] = useState(false);
  const [openExceedTimeModal, setOpenExceedTimeModal] = useState(false);
  const [hasRemaining, setHasRemaining] = useState(0);
  const [showPresaleBanner, setShowPresaleBanner] = useState(false);

  React.useEffect(() => {

    document.title = "Green-X Wallet"

    props.getUserInfo();
    props.getUserConfig();

    // display banner within timerange only
    const checkTimeRange = () => {
      const startDate = new Date('2024-05-25T12:00:00');
      const endDate = new Date('2024-05-26T18:00:00');
      const currentDate = new Date();

      if (currentDate >= startDate && currentDate <= endDate) {
        setShowPresaleBanner(true);
      } else {
        setShowPresaleBanner(false);
      }
    };

    checkTimeRange();
  }, []);

  //  check balance USDT
  var balanceUSDT = 0;
  if (props.wallet_balance) {
    balanceUSDT = props.wallet_balance['12'].Amount;
  }

  // get selectedAccountId
  var current_account_id = props.user.selectedAccountId;

  // call API to get remaining balance
  function getBalance() {
    return new Promise((resolve, reject) => {
      greenx
        .getRemainingPurchaseAmount({
          OMSId: 1,
          AccountId: current_account_id,
          ProductId: 45
        })
        .then(res => {
          resolve(setHasRemaining(res.RemainingPurchaseAmount));
        })
        .catch(err => {
          reject(console.log(err));
        });
    });
  }

  if (current_account_id) {
    const startDate = new Date('2024-05-25T12:00:00');
    const endDate = new Date('2024-05-26T18:00:00');
    const currentDate = new Date();

    if (currentDate >= startDate && currentDate <= endDate) {
      getBalance();
    }
  }

  // Popup modal settings
  const openModal = () => {
    const currentDate = new Date();
    const targetDate = new Date('2024-05-26T18:00:00');
    if (currentDate > targetDate) {
      openPresaleEndModal();
    } else {
      setOpenConfirmModal(true);
    }
  };
  const closeModal = () => {
    setOpenConfirmModal(false);
  };
  const openResultModal = () => {
    setOpenPurchaseResultModal(true);
  };
  const closeResultModal = () => {
    setOpenPurchaseResultModal(false);
  };
  const openPresaleEndModal = () => {
    setOpenExceedTimeModal(true);
  };
  const closePresaleEndModal = () => {
    setOpenExceedTimeModal(false);
  };

  var confirmDialog = () => {
    return React.createElement(
      Modal,
      {
        isOpen: openConfirmModal,
        title: context.t('Purchase Confirmation'),
        close: closeModal,
        footer: {
          buttonText: context.t('Confirm'),
          buttonStyle: 'additive',
          onClick: () => {
            return new Promise((resolve, reject) => {
              // put WNW buy API here
              greenx
                .buyToken({
                  OMSId: 1,
                  AccountId: current_account_id,
                  BaseProductId: 45, // WNW
                  QuoteProductId: 12, // USDT
                  BaseAmount: 5000
                })
                .then(res => {
                  console.log(res);
                  if (res.err) {
                    return resolve(window.alert('Order Fail 订单失败'));
                  } else {
                    return resolve(openResultModal());
                  }
                })
                .catch(err => {
                  console.log(err);
                  return reject(window.alert('Order Fail 订单失败,网络错误'));
                });
            });
          }
        }
      },
      React.createElement(
        'p',
        {
          style: { fontWeight: 'bold', marginTop: '10px', fontFamily: 'Arial' }
        },
        context.t(
          'To buy 5,000 WNW tokens @ $0.06 per WNW at the total value of USDT 300'
        )
      )
    );
  };

  var purchaseResultDialog = () => {
    return React.createElement(
      Modal,
      {
        isOpen: openPurchaseResultModal,
        title: context.t('Purchase Result'),
        close: closeResultModal,
        footer: {
          buttonText: context.t('OK'),
          buttonStyle: 'additive',
          onClick: () => {
            closeResultModal();
          }
        }
      },
      React.createElement(
        'p',
        { style: { fontWeight: 'bold', marginTop: '10px' } },
        context.t(
          'Congratulations, you have successfully purchased WNW tokens.'
        )
      )
    );
  };

  var exceedTimeDialog = () => {
    return React.createElement(
      Modal,
      {
        isOpen: openExceedTimeModal,
        title: context.t('Presales has Ended'),
        close: closePresaleEndModal,
        footer: {
          buttonText: context.t('OK'),
          buttonStyle: 'additive',
          onClick: () => {
            closePresaleEndModal();
          }
        }
      },
      React.createElement(
        'p',
        { style: { fontWeight: 'bold', marginTop: '10px' } },
        context.t(
          'We are sorry to inform you that the presales has ended.'
        )
      )
    );
  };

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      <div className={layoutClasses('container')}>
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Total Bonus Earned')}
        </div>
        <div className="interest-earned-container">
          <div className="interest-earned-message">
            <h4>
              {context.t(`To this date, you have earned:`)}{' '}
              <b className="interest-amount">{`$${props.getInterest()} USDT`}</b>
            </h4>
          </div>
        </div>
        {showPresaleBanner && (
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center buy-sto-container">
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                <img src={logo_wenew} />
                <h1 className="mb-0">{context.t(`WNW Pre-sale Now`)}</h1>
                <button
                  className={
                    balanceUSDT < 300 || hasRemaining < 5000
                      ? 'buy-sto-btn disabled'
                      : 'buy-sto-btn mt-4 mt-md-0'
                  }
                  style={{ marginLeft: '30px' }}
                  onClick={openModal}
                  disabled={balanceUSDT < 300 || hasRemaining < 5000}>
                  <h2 className="mb-0">
                    {hasRemaining >= 5000 ? (
                      <>{context.t(`ORDER NOW`)}</>
                    ) : (
                      <>{context.t(`PURCHASED`)}</>
                    )}
                  </h2>
                </button>
              </div>
              {balanceUSDT < 300 && hasRemaining >= 5000 ? (
                <div className="balanceMsg">
                  <div className="mt-3">
                    {context.t('You have insufficient balance.')}
                    {context.t(
                      ' Please ensure that you have 300 USDT available in your GreenX Wallet to place order.'
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              {hasRemaining < 5000 && (
                <div className="balanceMsg">
                  <div className="mt-3">
                    {context.t(
                      ' The presale only allows one purchase per user.'
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* <button
                className={
                  balanceUSDT < 300
                    ? 'buy-sto-btn disabled'
                    : 'buy-sto-btn mt-4 mt-md-0'
                }
                onClick={openModal}
                disabled={balanceUSDT < 300}>
                <h3 className="mb-0">{context.t(`ORDER NOW`)}</h3>
              </button> */}
            {confirmDialog()}
            {purchaseResultDialog()}
            {exceedTimeDialog()}
          </div>
        )}
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Funded Accounts')}
        </div>
        <div className={layoutClasses('wallet-card-container')}>
          <WalletCardGridContainer detailsLink={detailsLink} />
        </div>
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Unfunded Accounts')}
        </div>
        <div className={layoutClasses('wallet-list-container')}>
          <WalletRowListContainer detailsLink={detailsLink} />
        </div>
      </div>
      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

WalletsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

function userInterestSelector(state) {
  if (state.user) {
    let userConfig = state.user.userConfig;
    userConfig = userConfig.filter(config => config['Key'] === 'interest');
    if (userConfig.length > 0) {
      return userConfig[0]['Value'];
    }
    return '0.00';
  }
  return '0.00';
}
var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user,
    getInterest: () => userInterestSelector(state),
    wallet_balance:
      state.apexCore.position.positions[state.user.selectedAccountId]
  };
};
var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};
function compose(...morphisms) {
  let morphism = o => o;
  for (let m in morphisms) {
    let _morphism = morphism;
    morphism = o => m(_morphism(o));
  }
  morphism = morphisms[0];
  return morphism;
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAuthentication
)(WalletsPage);
