import React from 'react';
import PropTypes from 'prop-types';
import BuySellAmountRow from './BuySellAmountRow';

var BuySellPresetValuesRows = function BuySellPresetValuesRows(props) {
  var baseClasses = props.baseClasses,
    dealValues = props.dealValues,
    setAmount = props.setAmount,
    activeAmount = props.activeAmount,
    isBuySide = props.isBuySide,
    invertArrows = props.invertArrows;

  return React.createElement(
    React.Fragment,
    null,
    dealValues.map(function(deal) {
      return React.createElement(BuySellAmountRow, {
        key: deal.key,
        baseClasses: baseClasses,
        onClick: function onClick() {
          return setAmount(deal.amountRadio);
        },
        isActive: deal.price === +activeAmount,
        amountRadio: deal.amountRadio,
        isBuySide: isBuySide,
        value1: deal.value1,
        value2: deal.value2,
        invertArrows: invertArrows
      });
    })
  );
};

BuySellPresetValuesRows.propTypes = {
  baseClasses: PropTypes.func.isRequired,
  isBuySide: PropTypes.bool.isRequired,
  setAmount: PropTypes.func.isRequired,
  dealValues: PropTypes.array.isRequired
};

export default BuySellPresetValuesRows;
