import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

import logo_009_CQSE from '../pages/img/cloud-digimedia-009.png';
import logo_wenew from '../pages/img/wenew-logo.png';

const layoutClasses = getBEMClasses('purchase-form-page');

const sto_details = {
  "digau": {
    title: ' WENEW RWA ',
    image: logo_wenew,
    form_link: 'https://www.green-x.io/presale_form_AMA',
    session_expiry: {
      '001': {
        start: 'May 21, 2024 12:00:00',
        end: 'May 21, 2024 15:00:00',
        endTimeDisplay: '15:00:00'
      },
      '002': {
        start: 'May 21, 2024 15:00:00',
        end: 'May 21, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      },
      testing: {
        start: 'May 17, 2024 00:00:00',
        end: 'May 21, 2024 18:00:00',
        endTimeDisplay: '18:00:00'
      }
    }
  }
};

const PurchaseFormPage = (props, context) => {
  var sto = '';
  var source = { url: '', title: 'Presale Order Form', fullUrl: '' };

  const [showForm, setShowForm] = useState(true); // to control show/hide form
  const [sessionStatus, setSessionStatus] = useState({
    datetime: '',
    message: ''
  }); // display respective message
  const [countdown, setCountdown] = useState(''); // countdown to start time
  const [endCountdown, setEndCountdown] = useState(''); // countdown to end time
  const [CampaignStatus, setCampaignStatus] = useState('');

  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();

    // session validation
    let currentSession = sto_details[sto].session_expiry[sessionID];
    if (currentSession) {
      const targetStartTime = new Date(currentSession.start).getTime();
      const targetEndTime = new Date(currentSession.end).getTime();
      const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        if (currentTime >= targetStartTime && currentTime <= targetEndTime) {
          // show form when between the time range
          setCampaignStatus('after_start');
          setShowForm(true);
          setSessionStatus({
            datetime: '',
            message: ''
          });
        } else {
          // hide form when outside the timerange
          setShowForm(false);

          let sessionDateTimeStr =
            sto_details[sto].session_expiry[sessionID].start +
            ' - ' +
            sto_details[sto].session_expiry[sessionID].endTimeDisplay;
          // display message based on condition
          if (currentTime < targetStartTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: 'The Presale has not started yet.'
            });
          } else if (currentTime > targetEndTime) {
            setSessionStatus({
              datetime: sessionDateTimeStr,
              message: 'The Presale expired.'
            });
          }
        }

        if (currentTime >= targetEndTime) {
          setCampaignStatus('end');
        } else if (currentTime <= targetStartTime) {
          setCampaignStatus('before_start');
        }
      }, 500);

      const countdownInterval = setInterval(() => {
        const timeUntilStart = targetStartTime - new Date().getTime();
        if (timeUntilStart <= 0) {
          clearInterval(countdownInterval);
          setCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilStart / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilStart / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilStart / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilStart / 1000) % 60);
          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);

      const endTimeCountdownInterval = setInterval(() => {
        const timeUntilEnd = targetEndTime - new Date().getTime();
        if (timeUntilEnd <= 0) {
          clearInterval(endTimeCountdownInterval);
          setEndCountdown('');
          setSessionStatus({ datetime: '', message: '' });
        } else {
          const days = Math.floor(timeUntilEnd / (1000 * 60 * 60 * 24));
          const hours = Math.floor((timeUntilEnd / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((timeUntilEnd / (1000 * 60)) % 60);
          const seconds = Math.floor((timeUntilEnd / 1000) % 60);
          setEndCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
        clearInterval(countdownInterval);
        clearInterval(endTimeCountdownInterval);
      };
    } else {
      // hide form when session_id is invalid
      setShowForm(false);
      setSessionStatus({ datetime: '', message: 'Invalid Session' });
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  sto = urlParams.get('sto');
  var sessionID = urlParams.get('session_id');
  let ref_source = urlParams.get('ref');

  // set form url and append params
  source.url = sto_details[sto].form_link;
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&session_id=' +
    sessionID +
    '&language=' +
    localStorage.getItem('language') +
    '&ref=' +
    ref_source;

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />

      {/* only for showing iframe without countdown logic */}
      <iframe
        className="purchase-form-iframe"
        src={source.fullUrl}
        title={source.title}
        allow="clipboard-read; clipboard-write"
      />



      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

PurchaseFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PurchaseFormPage));
