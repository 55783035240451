import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { formatDateTime } from 'apex-web/lib/helpers/dateHelper';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { templateConfirmRenderer } from 'apex-web/lib/helpers/formTemplateHelper';

import './FiatDepositModalComponent.css';

var fiatDepositModalClasses = getBEMClasses('fiat-deposit-modal');

var shouldRenderBankInfo = function shouldRenderBankInfo(bankInfo) {
  return bankInfo.name && bankInfo.wireNumber && bankInfo.accountNumber;
};

var FiatDepositModalComponent = function FiatDepositModalComponent(props, context) {
  var _props$payload = props.payload,
      ProductSymbol = _props$payload.ProductSymbol,
      fullname = _props$payload.fullname,
      amount = _props$payload.amount,
      comments = _props$payload.comments,
      GivenName = _props$payload.GivenName,
      Amount = _props$payload.Amount,
      template = props.template,
      _props$templateInfo = props.templateInfo,
      templateInfo = _props$templateInfo === undefined ? {} : _props$templateInfo,
      payload = props.payload;


  var templateFields = templateConfirmRenderer(template, payload, fiatDepositModalClasses, context);

  return React.createElement(
    Modal,
    {
      isOpen: props.isOpen,
      title: React.createElement(
        'div',
        { className: fiatDepositModalClasses('header-content') },
        React.createElement(APIcon, {
          name: 'depositWithBorder',
          size: 44,
          customClass: fiatDepositModalClasses('icon')
        }),
        React.createElement(
          'div',
          { className: fiatDepositModalClasses('header-title') },
          context.t('Deposit') + ' ' + ProductSymbol
        )
      ),
      close: props.close,
      footer: {
        buttonText: context.t('Confirm'),
        buttonStyle: 'additive',
        onClick: function onClick() {
          props.submitDeposit(payload);

          if (props.showDepositIdModal) {
            props.openReferenceModal();
          }
        }
      },
      customClass: fiatDepositModalClasses() },
    React.createElement(
      'div',
      { className: fiatDepositModalClasses('title') },
      context.t('Confirmation')
    ),
    React.createElement(
      'div',
      { className: fiatDepositModalClasses() },
      templateFields.length > 0 ? templateFields : React.createElement(
        React.Fragment,
        null,
        React.createElement(APLabelWithText, {
          label: context.t('Full Name'),
          text: fullname || GivenName,
          customClass: fiatDepositModalClasses()
        }),
        React.createElement(APLabelWithText, {
          label: context.t('Amount to Deposit'),
          text: amount || Amount,
          customClass: fiatDepositModalClasses()
        }),
        React.createElement(APLabelWithText, {
          label: context.t('Note'),
          text: comments || '-',
          customClass: fiatDepositModalClasses()
        }),
        React.createElement(APLabelWithText, {
          label: context.t('Time'),
          text: formatDateTime(new Date()),
          customClass: fiatDepositModalClasses()
        })
      ),
      templateInfo.DepositWorkflow === 'ManualDeposit' && shouldRenderBankInfo(props.bankInfo) && React.createElement(
        React.Fragment,
        null,
        React.createElement(
          'div',
          { className: fiatDepositModalClasses('bank-info') },
          React.createElement(APLabelWithText, {
            label: context.t('Step 2: Wire the funds to this account'),
            customClass: fiatDepositModalClasses(),
            classModifiers: 'bank-info'
          }),
          React.createElement(APLabelWithText, {
            label: context.t('Bank Name:'),
            text: props.bankInfo.name,
            customClass: fiatDepositModalClasses(),
            classModifiers: 'bank-info'
          }),
          React.createElement(APLabelWithText, {
            label: context.t('Wire Number:'),
            text: props.bankInfo.wireNumber,
            customClass: fiatDepositModalClasses(),
            classModifiers: 'bank-info'
          }),
          React.createElement(APLabelWithText, {
            label: context.t('Account Number:'),
            text: props.bankInfo.accountNumber,
            customClass: fiatDepositModalClasses(),
            classModifiers: 'bank-info'
          })
        ),
        React.createElement(
          'div',
          { className: fiatDepositModalClasses('information') },
          React.createElement(
            'div',
            { className: fiatDepositModalClasses('information-text') },
            context.t('Make sure the wire transfer matches the Name and Amount stated in this Deposit Ticket.')
          ),
          React.createElement(
            'div',
            { className: fiatDepositModalClasses('information-text') },
            context.t('A copy of this Deposit Ticket will be emailed to you')
          )
        )
      )
    )
  );
};

FiatDepositModalComponent.propTypes = {
  payload: PropTypes.object,
  close: PropTypes.func.isRequired
};

FiatDepositModalComponent.defaultProps = {
  payload: {
    fullname: '',
    amount: '',
    comments: ''
  }
};

FiatDepositModalComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default FiatDepositModalComponent;