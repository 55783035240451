import React from 'react';
import { Link } from 'react-router-dom';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import CardImg from '../images/custom/cards.png';
import './CashOutPage.css';
import { RiBankLine } from 'react-icons/ri';
import { FaRegCreditCard } from 'react-icons/fa';
import { AiOutlineGift } from 'react-icons/ai';

const classes = getBEMClasses('eotc-buy-sell-layout');

const CashOutPage = () => {
  return (
    <React.Fragment>
      <div>
        <div className={classes('accent-bar')} />
        <div className={classes('container')}>
          <div className={classes('col', ['lg'])}>
            <div className="title-container">
              <div className="col-left">
                <h2>
                  Select how you would like to cash out from the exchange:
                </h2>
              </div>
              <div className="col-right">
                <img className="card-img" src={CardImg} />
              </div>
            </div>
            <div
              className={classes(
                'make-transaction-container container-with-shadow'
              )}>
              <div className="grid-container">
                {/* 
                <div className="grid-item">
                  <div className="grid-content">
                    <Link to="/cash-out/bank">
                      <RiBankLine className="card-icon" />
                      <h1>Bank Transfer</h1>
                    </Link>
                  </div>
                </div>
                */}
                <div className="grid-item">
                  <div className="grid-content">
                    <Link to="/cash-out/prepaid-cards">
                      <FaRegCreditCard className="card-icon" />
                      <h1>Prepaid Credit Card</h1>
                    </Link>
                  </div>
                </div>
                {/*
                <div className="grid-item">
                  <div className="grid-content">
                    <Link to="/cash-out/gift-cards">
                      <AiOutlineGift className="card-icon" />
                      <h1>Gift Card</h1>
                    </Link>
                  </div>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
        <div className={classes('footer')}>
          <PageFooterComponent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withAuthentication(CashOutPage);
