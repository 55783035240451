import { connect } from 'react-redux';
import { fetchingSelector, unfundedPositionsSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import WalletRowListComponent from './WalletRowListComponent';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  return {
    fetching: fetchingSelector(state),
    products: unfundedPositionsSelector(state),
    detailsLink: ownProps.detailsLink
  };
};

export default connect(mapStateToProps)(WalletRowListComponent);