import PropTypes from 'prop-types';

export var styleNames = {
  general: 'general',
  additive: 'additive',
  subtractive: 'subtractive'
};

export var styleNamePropType = PropTypes.oneOf([
  styleNames.general,
  styleNames.additive,
  styleNames.subtractive
]);
