import { createSelector } from 'reselect';

export var noDuplicateAccountsSelector = createSelector(
  function(state) {
    return state.user.accounts;
  },
  function(accounts) {
    // console.log("raw accounts", accounts)
    let uniqueAccounts = [];
    accounts.map(a => {
      if (uniqueAccounts.findIndex(e => e.AccountId === a.AccountId) === -1) {
        uniqueAccounts.push(a);
      }
    });

    return uniqueAccounts;
    // return accounts.filter(function (account) {
    //   return account.AccountType !== 'MarginAsset';
    // });
  }
);
