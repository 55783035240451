function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import { connect } from 'react-redux';
import { get } from 'lodash';
import SendReceiveSidePaneComponent from './SendReceiveSidePaneComponent';
import { createWithdrawTicket, getWithdrawFee as _getWithdrawFee } from 'apex-web/lib/redux/actions/withdrawActions';
import { requestTransferFunds, transferFunds, getTransfers } from 'apex-web/lib/redux/actions/transferActions';
import { getTransferRequestsRequested } from 'apex-web/lib/redux/actions/transferRequestActions';
import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';
import { fetchAccountWithdrawInfos as _fetchAccountWithdrawInfos } from 'apex-web/lib/redux/actions/eotcWithdrawActions';
import { selectPositionAndSave as _selectPositionAndSave } from 'apex-web/lib/redux/actions/positionActions';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var productId = get(ownProps, 'product.ProductId', null);
  var productSymbol = get(ownProps, 'product.ProductSymbol', null);
  var position = positionByProductIdSelector(productId)(state);
  var decimalPlaces = state.apexCore.product.decimalPlaces[productSymbol];
  var feeCharged = state.productManifest.manifest[productSymbol].feeCharged;
  var product = Object.assign({}, ownProps.product, { decimalPlaces: decimalPlaces,  feeCharged:feeCharged});
  var _config$global = config.global,
      allowTransfer = _config$global.allowTransfer,
      hideFees = _config$global.hideFees;
  var _state$withdraw = state.withdraw,
      fee = _state$withdraw.withdrawFee,
      template = _state$withdraw.template;
  var selectedAccountId = state.user.selectedAccountId;

  var TicketAmount = get(state, ['withdraw', 'TicketAmount'], 0);

  var verificationLevelConfig = get(state, ['user', 'verificationLevelConfigs', 'Products'], []).find(function (i) {
    return i.ProductId === productId;
  }) || {};

  return {
    position: position,
    fee: fee,
    template: template,
    product: product,
    allowTransfer: allowTransfer,
    hideFees: hideFees,
    selectedAccountId: selectedAccountId,
    TicketAmount: TicketAmount,
    verificationLevelConfig: verificationLevelConfig
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    receiveFunds: function receiveFunds(_ref) {
      var selectedAccountId = _ref.selectedAccountId,
          rest = _objectWithoutProperties(_ref, ['selectedAccountId']);

      return dispatch(requestTransferFunds(rest, ownProps.name)).then(function () {
        return dispatch(getTransferRequestsRequested(selectedAccountId));
      });
    },
    sendFunds: function sendFunds(_ref2) {
      var selectedAccountId = _ref2.selectedAccountId,
          rest = _objectWithoutProperties(_ref2, ['selectedAccountId']);

      return dispatch(transferFunds(rest, ownProps.name)).then(function () {
        return dispatch(getTransfers(selectedAccountId));
      });
    },
    sendToAddress: function sendToAddress(payload) {
      return dispatch(createWithdrawTicket(payload, ownProps.name));
    },
    getWithdrawFee: function getWithdrawFee(productId, amount) {
      return dispatch(_getWithdrawFee(productId, amount));
    },
    fetchAccountWithdrawInfos: function fetchAccountWithdrawInfos() {
      return dispatch(_fetchAccountWithdrawInfos());
    },
    selectPositionAndSave: function selectPositionAndSave(productId) {
      return dispatch(_selectPositionAndSave(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendReceiveSidePaneComponent);