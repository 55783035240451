let productReducer = (state = {}, { type, payload }) =>
  type === 'JOOMIO_SELECT_PRODUCT'
    ? ((state.selectedProduct = payload.productID), state)
    : type === 'JOOMIO_SELECT_FIAT_PRODUCT'
      ? ((state.selectedFiatProduct = payload.productID), state)
      : type === 'JOOMIO_SET_CRYPTO_BUY_AMOUNT'
        ? ((state.cryptoBuyAmount = payload.cryptoBuyAmount), state)
        : type === 'JOOMIO_SET_FIAT_PAY_AMOUNT'
          ? ((state.fiatPayAmount = payload.fiatPayAmount), state)
          : type === 'JOOMIO_SET_COMMISSION_AMOUNT'
            ? ((state.commissionAmount = payload.commissionAmount), state)
            : type === 'JOOMIO_SET_COMMISSION_RATE'
              ? ((state.commissionRate = payload.commissionRate), state)
              : type === 'JOOMIO_SET_CRYPTO_BUY_AMOUNT_0'
                ? ((state.cryptoBuyAmount0 = payload.cryptoBuyAmount0), state)
                : type === 'JOOMIO_SET_FIAT_PAY_AMOUNT_0'
                  ? ((state.fiatPayAmount0 = payload.fiatPayAmount0), state)
                  : state;
export default productReducer;
