var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { required, email, validateReceiverEmail } from 'apex-web/lib/helpers/formValidations';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import './ReceiveForm.css';

var bemClasses = getBEMClasses('receive-form');

var ReceiveForm = function (_React$Component) {
  _inherits(ReceiveForm, _React$Component);

  function ReceiveForm() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ReceiveForm);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ReceiveForm.__proto__ || Object.getPrototypeOf(ReceiveForm)).call.apply(_ref, [this].concat(args))), _this), _this.checkUserEmail = function (value) {
      var userEmail = _this.props.userEmail;

      return validateReceiverEmail(value, userEmail);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ReceiveForm, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          _props$product = _props.product,
          name = _props$product.ProductFullName,
          symbol = _props$product.ProductSymbol,
          decimalPlaces = _props$product.decimalPlaces,
          handleSubmit = _props.handleSubmit,
          invalid = _props.invalid,
          submitting = _props.submitting,
          onChange = _props.onChange,
          onSubmit = _props.onSubmit;

      return React.createElement(
        'form',
        { onSubmit: handleSubmit(onSubmit), className: bemClasses() },
        React.createElement(
          'section',
          { className: bemClasses('receive-from') },
          React.createElement(
            'p',
            { className: bemClasses('label-text') },
            this.context.t('Email Address to Request From')
          ),
          React.createElement(APInput, {
            name: 'ReceiverUsername',
            customClass: bemClasses(),
            onChange: handleSubmit(onChange),
            validate: [required, email, this.checkUserEmail]
          })
        ),
        React.createElement(
          'p',
          { className: bemClasses('label-text') },
          this.context.t('Amount of {name} to Request', { name: name })
        ),
        React.createElement(
          'section',
          { className: bemClasses('amounts') },
          React.createElement(APNumberInput, {
            name: 'Amount',
            customClass: bemClasses(),
            labelInInput: symbol,
            decimalPlaces: decimalPlaces,
            onChange: handleSubmit(onChange),
            validate: [required]
          })
        ),
        React.createElement(
          'section',
          { className: bemClasses('note') },
          React.createElement(
            'p',
            { className: bemClasses('label-text') },
            this.context.t('Add a Note '),
            React.createElement(
              'em',
              null,
              this.context.t('(Optional)')
            )
          ),
          React.createElement(APInput, { name: 'Notes', rows: 3, customClass: bemClasses() })
        ),
        React.createElement(
          APButton,
          {
            type: 'submit',
            customClass: bemClasses(),
            styleName: 'additive',
            disabled: invalid || submitting },
          this.context.t('Request'),
          ' ',
          name
        )
      );
    }
  }]);

  return ReceiveForm;
}(React.Component);

ReceiveForm.propTypes = {
  product: product.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ReceiveForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ReceiveForm;