import { connect } from 'react-redux';
import ApexChartComponent from './ApexChartComponent';
import { changeTickersInterval } from 'apex-web/lib/redux/actions/tickersActions';
import config from 'apex-web/lib/config/index';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

var locale = config.global.locale;

var mapStateToProps = function mapStateToProps(state) {
  var tickers = state.tickers,
    apexCore = state.apexCore;
  var level1 = apexCore.level1,
    level2 = apexCore.level2;

  var selectedInstrument = selectedInstrumentSelector(state);
  var selectedInstrumentId = selectedInstrument.InstrumentId;

  var marketPrice =
    selectedInstrumentId && level1[selectedInstrument.InstrumentId]
      ? level1[selectedInstrument.InstrumentId].LastTradedPx
      : 0;

  return {
    precision: convertIncrementToIntDecimalPlaces(
      selectedInstrument.PriceIncrement
    ),
    locale: locale,
    chartData: { level2: level2, tickers: tickers, marketPrice: marketPrice }
  };
};

var mapDispatchToProps = {
  changeTickersInterval: changeTickersInterval
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApexChartComponent);
