export let selectedProductSelector = state =>
  state.joomioProduct.selectedProduct;
export let selectedFiatProductSelector = state =>
  state.joomioProduct.selectedFiatProduct;
export let cryptoBuyAmountSelector = state =>
  state.joomioProduct.cryptoBuyAmount;
export let fiatPayAmountSelector = state => state.joomioProduct.fiatPayAmount;
export let commissionAmountSelector = state =>
  state.joomioProduct.commissionAmount;
export let commissionRateSelector = state => state.joomioProduct.commissionRate;
export let cryptoBuyAmount0Selector = state =>
  state.joomioProduct.cryptoBuyAmount0;
export let fiatPayAmount0Selector = state => state.joomioProduct.fiatPayAmount0;
