import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ReceiveFormComponent from './ReceiveFormComponent';

var mapStateToProps = function mapStateToProps(state) {
  var userEmail = state.user.userInfo.Email;
  return {
    userEmail: userEmail
  };
};

var ReceiveForm = reduxForm({
  form: 'ReceiveForm',
  onSubmit: function onSubmit() {}
})(ReceiveFormComponent);

export default connect(mapStateToProps, null)(ReceiveForm);