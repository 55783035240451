import React from 'react';
import resize from 'apex-web/lib/hocs/resize';
import { connect } from 'react-redux';
// import InstrumentRowComponent from 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent';
import InstrumentRowComponent from '../../components/InstrumentRow/InstrumentRowComponent';
import ConfirmLimitOrder from 'apex-web/lib/components/ConfirmLimitOrderModal/ConfirmLimitOrderModalContainer';
import OrderHistoryContainer from 'apex-web/lib/components/OrderHistoryComponents';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
// import 'apex-web/lib/layouts/TradingLayout/TradingLayout.css';
import './TradingLayout.css';
import ExchangePageSize from './ExchangePageSize';
import ExchangeChartsOut from './ExchangeChartsOut';
import config from '../../config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import instrumentPositionSelectors from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import L from 'lodash';
import Navbar from '../../components/NewNav';

const tradingLayoutClasses = getBEMClasses('trading-layout');

class ExchangePageOut extends React.Component {
  componentDidMount() {
    const instrumentPairId = {
      DIGauUSDT: 32,
      TS1USDT: 20,
      '2UTUSDT': 31,
      BTCUSDT: 40,
      ETHUSDT: 8,
      BCHUSDT: 3,
      LTCUSDT: 10,
      XRPUSDT: 41,
      USDTUSD: 24
    };
    const urlParams = new URLSearchParams(window.location.search);
    let coin = urlParams.get('coin');
    document.title = 'Green-X Trade';

    document.querySelector('.popover').classList.remove('popover');
    localStorage.setItem(
      'selectedInstrumentId',
      instrumentPairId[coin] ?? '40'
    );
  }

  render() {
    const {
      OrderHistoryData: { usePagination },
      TradingLayout: { maxLines }
    } = config;

    const orderHistoryConfig = {
      usePagination,
      maxLines,
      filterMode: 'selectedInstrument'
    };

    return (
      <React.Fragment>
        {/* why log out Nav bar is here ???  */}
        {/* <NavbarOut /> */}
        <Navbar />
        <div className={tradingLayoutClasses('container')}>
          <InstrumentRowComponent
            level1={this.props.getLevel1(
              this.props.selectedInstrument['InstrumentId']
            )}
            positions={this.props.positions}
          />
          <div className={tradingLayoutClasses('body')}>
            <div className={tradingLayoutClasses('chart-container')}>
              <ExchangeChartsOut
                width={this.props.width}
                symbol={this.props.formatSymbol(
                  this.props.selectedInstrument.Symbol
                )}
              />
            </div>
            <OrderHistoryContainer
              filterMode="selectedInstrument"
              config={orderHistoryConfig}
            />
            <ExchangePageSize height={this.props.height} />
          </div>
          <ConfirmLimitOrder />
        </div>
      </React.Fragment>
    );
  }
}

let pipe = (...fns) => arg => fns.reduce((b, a) => a(b), arg);
let mapStateToProps = state => ({
  positions: instrumentPositionSelectors(state),
  getLevel1: instrumentID => L.get(state.apexCore, ['level1', instrumentID]),
  selectedInstrument: selectedInstrumentSelector(state),
  formatSymbol: symbol => {
    if (symbol === 'USDTUSD') {
      return `KRAKEN:${symbol}`;
    } else if (symbol === 'RVNUSDT') {
      return `BINANCE:${symbol}`;
    } else {
      return `BITSTAMP:${symbol}`;
    }
  }
});
export default pipe(
  connect(mapStateToProps),
  resize
)(ExchangePageOut);
