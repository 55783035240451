import { connect } from 'react-redux';
import OrderBookComponent from './OrderBookComponent';
import { cancelAllOrders, cancelBuyOrders, cancelSellOrders, cancelOrders } from 'apex-web/lib/redux/actions/orderHistoryActions';
import { formatNumberToLocale } from '../../helpers/numberFormatter';
import { buyQuantityClicked, buyPriceClicked, sellQuantityClicked, sellPriceClicked } from 'apex-web/lib/redux/actions/orderEntryActions';
import { level2Selector, spreadSelector } from 'apex-web/lib/redux/selectors/level2Selectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { openOrdersSelectorFactory, buyOpenOrdersSelector, sellOpenOrdersSelector } from 'apex-web/lib/redux/selectors/orderHistorySelectors';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

var makeMapStateToProps = function makeMapStateToProps() {
  var openOrdersSelector = openOrdersSelectorFactory();

  return function (state) {
    var selectedInstrument = selectedInstrumentSelector(state);
    var selectedInstrumentId = selectedInstrument.InstrumentId;
    var fetching = state.apexCore.level2.fetching;
    var decimalPlaces = state.apexCore.product.decimalPlaces;

    var filter = { filterMode: 'selectedInstrument' };
    var level1 = state.level1[selectedInstrumentId]
    var level2 = level2Selector(state, filter);
    var openOrders = openOrdersSelector(state, filter);
    var buyOpenOrders = buyOpenOrdersSelector(state, filter);
    var sellOpenOrders = sellOpenOrdersSelector(state, filter);
    var spread = spreadSelector(state, filter);

    var convertedQuantityIncrement = convertIncrementToIntDecimalPlaces(selectedInstrument.QuantityIncrement);
    var convertedPriceIncrement = convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement);

    return {
      level2: level2,
      level1: level1,
      spread: formatNumberToLocale(spread, convertIncrementToIntDecimalPlaces(selectedInstrument.PriceIncrement)),
      decimalPlaces: decimalPlaces,
      fetching: fetching,
      selectedInstrumentId: selectedInstrumentId,
      openOrders: openOrders,
      buyOpenOrders: buyOpenOrders,
      sellOpenOrders: sellOpenOrders,
      selectedInstrument: selectedInstrument,
      convertedQuantityIncrement: convertedQuantityIncrement,
      convertedPriceIncrement: convertedPriceIncrement
    };
  };
};

var mapDispatchToProps = {
  cancelAllOrders: cancelAllOrders,
  cancelBuyOrders: cancelBuyOrders,
  cancelSellOrders: cancelSellOrders,
  cancelOrders: cancelOrders,
  buyQuantityClicked: buyQuantityClicked,
  buyPriceClicked: buyPriceClicked,
  sellQuantityClicked: sellQuantityClicked,
  sellPriceClicked: sellPriceClicked
};

export default connect(makeMapStateToProps, mapDispatchToProps)(OrderBookComponent);