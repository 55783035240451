import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InnerSelect } from 'apex-web/lib/components/common/APSelect';

var ProductSelector = function(props, context) {
  React.useEffect(() => {
    new Promise(r => setTimeout(r, 1e3))
      .then(() => {
        props.onSelectProduct(props.products[0].ProductId);
      })
      .catch(() => {});
  }, []);
  /*
  React.useEffect(() => {
    if (typeof props.selectedProduct === 'undefined') {
      Promise.resolve()
        .then(() => props.onSelectProduct(props.products[0].ProductId))
        .then(() => new Promise(r => setTimeout(r, 1e3)))
        .then(() => props.onSelectProduct(-1))
        .catch(alert);
    } else if (props.selectedProduct == -1) {
      props.onSelectProduct(props.products[0].ProductId);
    }
  }, [props.selectedProduct]);
  */
  return React.createElement(
    'div',
    { className: props.baseClasses('crypto-container') },
    React.createElement(InnerSelect, {
      showTriangles: true,
      name: 'instrument',
      label: props.label, //context.t(props.label),
      customClass: props.baseClasses(),
      onSelect: props.onSelectProduct,
      value: props.selectedProduct,
      options: props.products.map(function(product) {
        return {
          value: product.ProductId,
          label: product.ProductFullName
        };
      })
    })
  );
};
export default ProductSelector;
