import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { isBuySideBuySellFormSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import {
  synchronizeQuantityWithPrice,
  synchronizePriceWithQuantity
} from 'apex-web/lib/redux/actions/orderEntryActions';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import get from 'lodash/get';
import BuySellCustomRowComponent from './BuySellCustomRowComponent';

var selector = formValueSelector('buySell');

var mapStateToProps = function mapStateToProps(state) {
  return {
    isBuySide: isBuySideBuySellFormSelector(state),
    activeAmount: selector(state, 'amountRadio'),
    formObj: get(state, 'form.buySell', { values: {} }),
    selectedInstrument: selectedInstrumentSelector(state)
  };
};

var mapDispatchToProps = {
  setAmount: function setAmount(amount) {
    return change('buySell', 'amountRadio', amount);
  },
  synchronizeQuantityWithPrice: synchronizeQuantityWithPrice,
  synchronizePriceWithQuantity: synchronizePriceWithQuantity
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuySellCustomRowComponent);
