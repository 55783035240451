var _createClass = (function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === "object" || typeof call === "function")
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError(
      "Super expression must either be null or a function, not " +
      typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

/* global TradingView */

import React from "react";
import PropTypes from "prop-types";
import ChartAPI from "apex-web/lib/services/tradingView/ChartAPI";
import jstz from "jstimezonedetect";
import Spinner from "apex-web/lib/components/common/Spinner/Spinner";
import path from "apex-web/lib/helpers/path";
import config from "apex-web/lib/config/index";
import { getGateway } from "apex-web/lib/helpers/wsHelper";
import { getCSSVar } from "apex-web/lib/helpers/cssVarHelper";
import { getBEMClasses } from "apex-web/lib/helpers/cssClassesHelper";
import "./TVChartComponent.css";
import { convertIncrementToIntDecimalPlaces } from "apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper";
import { convertTickerIntervalToTradingViewResolution } from "apex-web/lib/helpers/tickerHelper";

var tvChartClasses = getBEMClasses(["tv-chart"]);

/* These items were either non-working or buggy within our TradingView implementation,
and should stay disabled until they are fixed or reassessed. */
var disabledFeatures = [
  // "header_settings",
  // "control_bar",
  // "context_menus",
  "header_symbol_search",
  "header_compare",
  // "use_localstorage_for_settings",
  "save_chart_properties_to_local_storage",
  // "show_chart_property_page",
  // "header_resolutions",
  // "go_to_date",
];

var TVChartComponent = (function (_React$Component) {
  _inherits(TVChartComponent, _React$Component);

  function TVChartComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, TVChartComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          TVChartComponent.__proto__ ||
          Object.getPrototypeOf(TVChartComponent)).call.apply(
            _ref,
            [this].concat(args)
          )
      )),
        _this)),
        (_this.script = function (location) {
          return new Promise(function (resolve) {
            _this.scripts[location] = document.createElement("script");
            _this.scripts[location].src = path(location);
            _this.scripts[location].type = "text/javascript";
            _this.scripts[location].async = true;
            _this.scripts[location].onload = resolve;
            document.body.appendChild(_this.scripts[location]);
          });
        }),
        (_this.createChart = function (dataFeed) {
          var loading_BgColor = _this.cssVariable(
            "--tv-chart__loading-screen-bg-color"
          );
          var bgColor = _this.cssVariable("--tv-chart__bg-color");
          var gridColor = _this.cssVariable("--tv-chart__grid-color");
          var gridColorVertical = _this.cssVariable(
            "--tv-chart__grid-vertical-color"
          );
          var gridColorHorizontal = _this.cssVariable(
            "--tv-chart__grid-horizontal-color"
          );
          var scaleFontColor = _this.cssVariable("--tv-chart__scale-font-color");
          var scaleLineColor = _this.cssVariable("--tv-chart__scale-line-color");
          var scaleBgColor = _this.cssVariable("--tv-chart__scale-bg-color");
          var graphUpColor = _this.cssVariable("--tv-chart__graph-up-color");
          var graphDownColor = _this.cssVariable("--tv-chart__graph-down-color");
          var graphBorderUpColor = _this.cssVariable(
            "--tv-chart__graph-border-up-color"
          );
          var graphBorderDownColor = _this.cssVariable(
            "--tv-chart__graph-border-down-color"
          );
          var graphUpOpaqueColor =
            "rgba(" + _this.cssVariable("--pc__accent-additive") + ", .3)";
          var graphDownOpaqueColor =
            "rgba(" + _this.cssVariable("--pc__accent-subtractive") + ", .3)";

          // var custom_css_url = '/vendor/tradingView/charting_library-dark-theme.css';
          var chartOptions = {
            theme: "dark",
            symbol: _this.props.selectedInstrument.Symbol,
            datafeed: dataFeed,
            library_path: path("/vendor/tradingView/"),
            autosize: true,
            interval: convertTickerIntervalToTradingViewResolution(
              config.TickerData.defaultInterval
            ),
            container_id: "trading-view-chart",
            loading_screen: { backgroundColor: loading_BgColor },
            // custom_css_url: path(custom_css_url),
            settings_overrides: {
              'timeframe': '6h',
            },
            overrides: {
              // 'paneProperties.background': bgColor,
              // 'paneProperties.gridProperties.color': gridColor,
              // 'paneProperties.vertGridProperties.color': gridColorVertical,
              // 'paneProperties.horzGridProperties.color': gridColorHorizontal,
              // 'paneProperties.topMargin': 15,
              // 'paneProperties.bottomMargin': 25,
              // 'scalesProperties.textColor': scaleFontColor,
              // 'scalesProperties.lineColor': scaleLineColor,
              // 'scalesProperties.showLeftScale': false,
              // 'scalesProperties.showRightScale': true,
              // 'scalesProperties.backgroundColor': scaleBgColor,
              // volumePaneSize: 'large',
              // 'mainSeriesProperties.candleStyle.upColor': graphUpColor,
              // 'mainSeriesProperties.candleStyle.downColor': graphDownColor,
              // 'mainSeriesProperties.candleStyle.borderUpColor': graphBorderUpColor,
              // 'mainSeriesProperties.candleStyle.borderDownColor': graphBorderDownColor,
              // 'mainSeriesProperties.candleStyle.drawWick': true,
              // 'mainSeriesProperties.hollowCandleStyle.upColor': graphUpColor,
              // 'mainSeriesProperties.hollowCandleStyle.downColor': bgColor,
              // 'mainSeriesProperties.hollowCandleStyle.borderUpColor': graphBorderUpColor,
              // 'mainSeriesProperties.hollowCandleStyle.borderDownColor': graphBorderDownColor,
              // 'mainSeriesProperties.hollowCandleStyle.drawWick': false,
              // 'mainSeriesProperties.haStyle.upColor': graphUpColor,
              // 'mainSeriesProperties.haStyle.downColor': graphDownColor,
              // 'mainSeriesProperties.haStyle.borderUpColor': graphBorderUpColor,
              // 'mainSeriesProperties.haStyle.borderDownColor': graphBorderDownColor,
              // 'mainSeriesProperties.haStyle.drawWick': false,
              // 'mainSeriesProperties.barStyle.upColor': graphUpColor,
              // 'mainSeriesProperties.barStyle.downColor': graphDownColor,
              // 'mainSeriesProperties.lineStyle.color': graphUpColor,
              // 'mainSeriesProperties.lineStyle.linewidth': '4',
              // 'mainSeriesProperties.areaStyle.color1': graphUpColor,
              // 'mainSeriesProperties.areaStyle.color2': graphUpOpaqueColor,
              // 'mainSeriesProperties.areaStyle.linecolor': graphUpColor,
              // 'mainSeriesProperties.baselineStyle.topFillColor1': graphUpOpaqueColor,
              // 'mainSeriesProperties.baselineStyle.topFillColor2': graphUpOpaqueColor,
              // 'mainSeriesProperties.baselineStyle.bottomFillColor1': graphDownOpaqueColor,
              // 'mainSeriesProperties.baselineStyle.bottomFillColor2': graphDownOpaqueColor,
              // 'mainSeriesProperties.baselineStyle.topLineColor': graphUpColor,
              // 'mainSeriesProperties.baselineStyle.bottomLineColor': graphDownColor
            },
            studies_overrides: {
              "volume.volume.color.0": graphDownOpaqueColor,
              "volume.volume.color.1": graphUpOpaqueColor,
            },
          };

          var localOverrides = config.TradingView || {};

          var disabled_features = void 0;
          if ("disabled_features" in localOverrides) {
            disabled_features = [].concat(
              _toConsumableArray(
                new Set(
                  [].concat(
                    disabledFeatures,
                    _toConsumableArray(localOverrides.disabled_features)
                  )
                )
              )
            );
          } else {
            disabled_features = disabledFeatures;
          }

          var enabled_features = [];
          if ("enabled_features" in localOverrides) {
            enabled_features = localOverrides.enabled_features.filter(function (
              item
            ) {
              return !disabledFeatures.includes(item);
            });
          }

          // For some reasons TradingView.onready is not fired, timeout is used as fallback to initialize chart after 1.5 sec
          _this.timeout = setTimeout(function () {
            _this.chart = new TradingView.widget(
              Object.assign({}, chartOptions, localOverrides, {
                disabled_features: disabled_features,
                enabled_features: enabled_features,
              })
            );
            _this.chart.onChartReady(function () {
              _this.setState({ chartInitialized: true });
            });
          }, 1500);
        }),
        _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(TVChartComponent, [
    {
      key: "componentDidUpdate",
      value: function componentDidUpdate() {
        this.initChart();
      },
    },
    {
      key: "shouldComponentUpdate",
      value: function shouldComponentUpdate(nextProps, nextState) {
        if (
          !this.props.selectedInstrument.InstrumentId &&
          nextProps.selectedInstrument.InstrumentId
        ) {
          return true;
        }
        return (
          !!window.TradingView && !!nextState && !!nextState.chartInitialized
        );
      },
    },
    {
      key: "componentDidMount",
      value: function componentDidMount() {
        var _this2 = this;

        this.cssVariable = getCSSVar();
        this.scripts = {};

        this.script("/vendor/jquery-3.3.1.min.js")
          .then(function () {
            return _this2.script("/vendor/tradingView/charting_library.min.js");
          })
          .then(function () {
            _this2.initChart();
          });
      },
    },
    {
      key: "initChart",
      value: function initChart() {
        if (!this.props.selectedInstrument.InstrumentId) return;
        this.wsUri = getGateway();
        var timezone = jstz.determine().name();
        var currentTimezone = timezone ? timezone : config.TradingView.timezone;

        if (!this.chart) {
          if (!window.tvChartDataFeed) {
            window.tvChartDataFeed = new ChartAPI.UDFCompatibleDatafeed(
              this.wsUri,
              null,
              this.props.customGateway,
              currentTimezone,
              function (increment) {
                return convertIncrementToIntDecimalPlaces(increment);
              }
            );
          }

          this.createChart(window.tvChartDataFeed);
        } else {
          this.chart.setSymbol(
            this.props.selectedInstrument.Symbol,
            convertTickerIntervalToTradingViewResolution(
              config.TickerData.defaultInterval
            )
          );
        }
      },
    },
    {
      key: "componentWillUnmount",
      value: function componentWillUnmount() {
        clearTimeout(this.timeout);
        if (this.chart) {
          try {
            this.chart.remove();
          } catch (e) {
            console.warn("silently handled chart error: ", e);
          }
          this.scripts["/vendor/tradingView/charting_library.min.js"].remove();
          this.scripts["/vendor/jquery-3.3.1.min.js"].remove();
        }
      },
    },
    {
      key: "render",
      value: function render() {
        return React.createElement(
          "div",
          {
            className: tvChartClasses("", {
              "with-depth-chart":
                config.TradingLayout.showDepthChart &&
                window.innerHeight >= 1080,
            }),
          },
          React.createElement(
            "div",
            {
              id: "trading-view-chart",
              className: tvChartClasses("chart-container"),
            },
            React.createElement(Spinner, null)
          )
        );
      },
    },
  ]);

  return TVChartComponent;
})(React.Component);

TVChartComponent.propTypes = {
  selectedInstrument: PropTypes.object,
};

TVChartComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default TVChartComponent;
