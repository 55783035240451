import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'apex-web/lib/components/common/Modal/Modal';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import './DepositReferenceIdModal.css';

var depositReferenceIdModalClasses = getBEMClasses('deposit-reference-id-modal');

var DepositReferenceIdModal = function DepositReferenceIdModal(props, context) {
  return React.createElement(
    Modal,
    {
      isOpen: !!(props.referenceCode && props.isOpen),
      title: React.createElement(
        'div',
        { className: depositReferenceIdModalClasses('header-content') },
        React.createElement(APIcon, {
          name: 'depositWithBorder',
          size: 44,
          customClass: depositReferenceIdModalClasses('icon')
        }),
        React.createElement(
          'div',
          { className: depositReferenceIdModalClasses('header-title') },
          context.t('Deposit') + ' ' + props.ProductSymbol
        )
      ),
      close: props.close,
      footer: {
        buttonText: context.t('OK'),
        buttonStyle: 'additive',
        onClick: function onClick() {
          return props.close;
        }
      },
      customClass: depositReferenceIdModalClasses() },
    React.createElement(
      'p',
      { className: depositReferenceIdModalClasses('title') },
      context.t('Your deposit ticket has been created successfully.')
    ),
    React.createElement(
      'div',
      {
        className: depositReferenceIdModalClasses('reference-code-container') },
      React.createElement(
        'p',
        { className: depositReferenceIdModalClasses('reference-code-title') },
        context.t('Please note the following deposit ticket ID for your reference:')
      ),
      React.createElement(
        'p',
        { className: depositReferenceIdModalClasses('reference-code') },
        props.referenceCode
      )
    )
  );
};

DepositReferenceIdModal.propTypes = {
  close: PropTypes.func.isRequired
};

DepositReferenceIdModal.defaultProps = {
  referenceCode: ''
};

DepositReferenceIdModal.contextTypes = {
  t: PropTypes.func.isRequired
};

export default DepositReferenceIdModal;