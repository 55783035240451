var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React from 'react';
import PropTypes from 'prop-types';
import { getTotalRender, getAvailableBalance } from 'apex-web/lib/helpers/withdrawHelper';
import { product } from 'apex-web/lib/propTypes/sendReceiveComponent';
import SendReceiveHeaderComponent from './SendReceiveHeader/SendReceiveHeaderComponent';
import SendReceiveFormTabsComponent from './SendReceiveFormTabs/SendReceiveFormTabsComponent';
import SendFormContainer from './SendForm/SendFormContainer';
import ReceiveContainer from './Receive/ReceiveContainer';
import SendReceiveDetailsComponent from './SendReceiveDetails/SendReceiveDetailsComponent';
import SendReceiveConfirmModal from './SendReceiveConfirmModal/SendReceiveConfirmModalComponent';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './SendReceiveSidePane.css';

var sendReceiveClasses = getBEMClasses('send-receive');

var SendReceiveSidePane = function (_React$Component) {
  _inherits(SendReceiveSidePane, _React$Component);

  function SendReceiveSidePane(props) {
    _classCallCheck(this, SendReceiveSidePane);

    var _this = _possibleConstructorReturn(this, (SendReceiveSidePane.__proto__ || Object.getPrototypeOf(SendReceiveSidePane)).call(this, props));

    _this.toggleUseExternalAddress = function () {
      _this.setState({ useExternalAddress: !_this.state.useExternalAddress });
    };

    // this state is for switching Network, ERC-20 & BEP-20
    _this.toggleNetworkAddress = function (network_name) {
      _this.setState({ NetworkAddress: network_name });
    };

    _this.onUpdate = function () {
      _this.getWithdrawFee();
      _this.setTotal();
    };

    _this.setTotal = function () {
      var position = _this.props.position;
      var _this$state = _this.state,
        Amount = _this$state.data.Amount,
        fee = _this$state.fee;

      var balance = getAvailableBalance(position.Amount, position.Hold);
      var amount = parseFloat(Amount);
      var total = 0;

      if (amount) {
        total = getTotalRender(balance, amount, fee);
      }

      _this.setState({ total: total });
    };

    _this.getWithdrawFee = function () {
      var _this$props = _this.props,
        ProductId = _this$props.product.ProductId,
        getWithdrawFee = _this$props.getWithdrawFee;
      var Amount = _this.state.data.Amount;


      if (!isNaN(Amount)) {
        getWithdrawFee(ProductId, Amount);
      }
    };

    _this.onDataUpdate = function (data) {
      var _this$state2 = _this.state,
        Amount = _this$state2.data.Amount,
        isSend = _this$state2.isSend;

      _this.setState({ data: data }, function () {
        if (isSend && data.Amount !== Amount) {
          _this.onUpdate();
        }
      });
    };

    _this.openConfirmModal = function (data) {
      return _this.setState({ openConfirmModal: true, data: data });
    };

    _this.closeConfirmModal = function () {
      return _this.setState({ openConfirmModal: false });
    };

    _this.onTabChange = function () {
      _this.setState({
        isSend: !_this.state.isSend,
        data: {},
        useExternalAddress: true
      });
    };

    _this.submitData = function () {
      var _this$props2 = _this.props,
        receiveFunds = _this$props2.receiveFunds,
        sendFunds = _this$props2.sendFunds,
        sendToAddress = _this$props2.sendToAddress,
        ProductId = _this$props2.product.ProductId,
        template = _this$props2.template,
        TemplateType = _this$props2.template.TemplateType,
        selectedAccountId = _this$props2.selectedAccountId;
      var _this$state3 = _this.state,
        isSend = _this$state3.isSend,
        useExternalAddress = _this$state3.useExternalAddress,
        data = _this$state3.data,
        _this$state3$data = _this$state3.data,
        Notes = _this$state3$data.Notes,
        ReceiverUsername = _this$state3$data.ReceiverUsername,
        Amount = _this$state3$data.Amount;


      var payload = {
        Notes: Notes || '',
        ReceiverUsername: ReceiverUsername,
        Amount: Amount,
        ProductId: ProductId,
        selectedAccountId: selectedAccountId
      };

      var addressPayload = {
        ProductId: ProductId,
        Amount: Amount,
        TemplateType: TemplateType,
        TemplateForm: Object.assign({}, template, data)
      };

      if (isSend) {
        if (useExternalAddress) {
          sendToAddress(addressPayload);
        } else {
          sendFunds(payload);
        }
      } else {
        receiveFunds(payload);
      }
      _this.closeConfirmModal();
    };

    _this.state = {
      isSend: props.isSend,
      useExternalAddress: true,
      data: {},
      openConfirmModal: false,
      fee: null
    };
    return _this;
  }

  _createClass(SendReceiveSidePane, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.props.onSidePaneOpen({
        customClass: this.props.showDetails ? 'retail-sidepane-with-details' : 'retail-sidepane',
        hideHeader: true
      });

      this.props.selectPositionAndSave(this.props.position.ProductId);

      if (this.props.verificationLevelConfig.RequireWhitelistedAddress) {
        this.props.fetchAccountWithdrawInfos();
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      if (prevState.fee !== this.state.fee) {
        this.setTotal();
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
        product = _props.product,
        _props$position = _props.position,
        Amount = _props$position.Amount,
        Hold = _props$position.Hold,
        fee = _props.fee,
        showDetails = _props.showDetails,
        allowTransfer = _props.allowTransfer,
        hideFees = _props.hideFees,
        TicketAmount = _props.TicketAmount,
        verificationLevelConfig = _props.verificationLevelConfig;
      var _state = this.state,
        isSend = _state.isSend,
        useExternalAddress = _state.useExternalAddress,
        // for switching Network
        NetworkAddress = _state.NetworkAddress,
        data = _state.data,
        openConfirmModal = _state.openConfirmModal,
        total = _state.total;

      var balance = getAvailableBalance(Amount, Hold);

      return React.createElement(
        'div',
        { className: sendReceiveClasses() },
        React.createElement(
          'section',
          { className: sendReceiveClasses('main') },
          React.createElement(SendReceiveHeaderComponent, { product: product }),
          React.createElement(
            'section',
            { className: sendReceiveClasses('main-form') },
            React.createElement(SendReceiveFormTabsComponent, {
              isSend: isSend,
              toggleTab: this.onTabChange
            }),
            isSend ? React.createElement(SendFormContainer, {
              product: product,
              useExternalAddress: useExternalAddress,
              balance: balance,
              fee: fee,
              total: total,
              showDetails: showDetails,
              toggleUseExternalAddress: this.toggleUseExternalAddress,
              // for switching network
              toggleNetworkAddress: this.toggleNetworkAddress,
              NetworkAddress: NetworkAddress,
              // end
              onChange: this.onDataUpdate,
              onSubmit: this.openConfirmModal,
              allowTransfer: allowTransfer,
              verificationLevelConfig: verificationLevelConfig
            }) : React.createElement(ReceiveContainer, {
              product: product,
              useExternalAddress: useExternalAddress,
              toggleUseExternalAddress: this.toggleUseExternalAddress,
              // for switching network
              toggleNetworkAddress: this.toggleNetworkAddress,
              NetworkAddress: NetworkAddress,
              // end
              onChange: this.onDataUpdate,
              onSubmit: this.openConfirmModal,
              allowTransfer: allowTransfer
            })
          )
        ),
        showDetails && React.createElement(
          'section',
          { className: sendReceiveClasses('side') },
          React.createElement(
            'section',
            {
              className: sendReceiveClasses(isSend ? 'send-side-details' : 'side-details')
            },
            React.createElement(SendReceiveDetailsComponent, {
              isSend: isSend,
              useExternalAddress: useExternalAddress,
              details: data,
              balance: balance,
              total: total,
              product: product,
              TicketAmount: TicketAmount,
              fee: fee
            })
          )
        ),
        React.createElement(SendReceiveConfirmModal, {
          isOpen: openConfirmModal,
          useExternalAddress: useExternalAddress,
          close: this.closeConfirmModal,
          product: product,
          isSend: isSend,
          values: data,
          total: total,
          fee: fee,
          onConfirm: this.submitData,
          hideFees: hideFees
        })
      );
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.fee !== prevState.fee) {
        return { fee: nextProps.fee };
      }
      return null;
    }
  }]);

  return SendReceiveSidePane;
}(React.Component);

SendReceiveSidePane.defaultProps = {
  showDetails: true,
  onSidePaneOpen: function onSidePaneOpen() { },
  isSend: true
};

SendReceiveSidePane.propTypes = {
  allowTransfer: PropTypes.bool.isRequired,
  openRetailSendSidePane: PropTypes.func,
  openRetailReceiveSidePane: PropTypes.func,
  product: product,
  position: PropTypes.shape({
    AccountId: PropTypes.number,
    Amount: PropTypes.number,
    Hold: PropTypes.number,
    OMSId: PropTypes.number,
    PendingDeposits: PropTypes.number,
    PendingWithdraws: PropTypes.number,
    ProductId: PropTypes.number,
    ProductSymbol: PropTypes.string,
    TotalDayDeposits: PropTypes.number,
    TotalDayWithdraws: PropTypes.number,
    TotalMonthWithdraws: PropTypes.number,
    TotalYearWithdraws: PropTypes.number
  })
};

SendReceiveSidePane.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendReceiveSidePane;