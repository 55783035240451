import { connect } from 'react-redux';
import { getProductFullName } from 'apex-web/lib/helpers/productHelper';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import { getProductByIdSelector } from 'apex-web/lib/redux/selectors/productPositionSelectors';
import {
  isBuySideBuySellFormSelector,
  tradingDisabledSelector
} from 'apex-web/lib/redux/selectors/buySellSelectors';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';

import BuySellOrderButtonComponent from './BuySellOrderButtonComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    VerificationRequiredComponent: VerificationRequiredContainer
  };
};

export default connect(mapStateToProps)(BuySellOrderButtonComponent);
