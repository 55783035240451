import React from 'react';
import { connect } from 'react-redux';
import {
  logout,
  redirectFromLogin,
} from 'apex-web/lib/redux/actions/authActions';
import apex from 'apex-web/lib/apex'
import { Redirect } from 'react-router-dom';

function LogoutPage(props) {
  let {logout} = props;
  let externalId = localStorage.getItem('externalId');

  React.useEffect(() => {
    localStorage.removeItem('externalId');
  }, []);
  React.useEffect(() => {
    logout();
  }, [logout]);

  return (
    typeof externalId === 'string' ?
    React.createElement('span', {}, 'Logging out...') :
    React.createElement(Redirect, {
      to: '/2uchat/login',
    })
  );
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    shouldRedirect: state.auth.shouldRedirect,
    userId: state.auth.userId,
    sessionToken: state.auth.token,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);