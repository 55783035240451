import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
//import BuySellOrderConfirmationModal from '../BuySellOrderConfirmationModal';
import BuySellTabsContainer from './BuySellTabs';
import ProductSelector from './ProductSelector';
import FiatProductSelector from './FiatProductSelector';
import BuySellOrderButton from './BuySellOrderButton';
import BuySellPresetValuesRowsContainer from './BuySellPresetValuesRows';
import BuySellCustomRowContainer from './BuySellCustomRow';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import L from 'lodash';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import APButton from '../APButton';
import { Field } from 'redux-form';
import BTC from '../../../images/product-icons/btc-48px.svg';
import ETH from '../../../images/product-icons/eth-48px.svg';
import USDT from '../../../images/product-icons/usdt-48px.svg';
import BCH from '../../../images/product-icons/bch-48px.svg';
import LTC from '../../../images/product-icons/ltc-48px.svg';
import XRP from '../../../images/product-icons/xrp-48px.svg';
import './Joomio.css';

let recurse = fn => {
  let rec = (fn, ...args) => ({ rec, thunk: () => fn(...args) });
  return (fn => (...args) =>
    (x => x(x))(x => (...args) =>
      Promise.resolve(() => fn((...args) => x(x)(...args), ...args)).then(rec)
    )(...args).then(async s => {
      while (s && s.rec === rec) {
        s = await s.thunk();
      }
      return s;
    }))(fn);
};
let waitUntil = pred =>
  recurse(s =>
    Promise.resolve()
      .then(pred)
      .then(
        x =>
          x
            ? () => Promise.resolve({})
            : () => new Promise(resolve => setTimeout(resolve, 1e2)).then(s)
      )
      .then(cb => cb())
  )();

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('buy-sell');

let withKycVerification = verified => Component =>
  verified
    ? Component
    : React.createElement(APButton, {
        baseClasses: baseClasses,
        onClick: e => (
          e.preventDefault(), (window.location = '/settings/verification-level')
        ),
        label: 'Do KYC Verification'
      });

var JoomioComponent = (function(_React$Component) {
  _inherits(JoomioComponent, _React$Component);

  function JoomioComponent() {
    _classCallCheck(this, JoomioComponent);

    return _possibleConstructorReturn(
      this,
      (
        JoomioComponent.__proto__ || Object.getPrototypeOf(JoomioComponent)
      ).apply(this, arguments)
    );
  }

  function renderProductIcon(product) {
    if (product === 'BTC') {
      return BTC;
    } else if (product === 'ETH') {
      return ETH;
    } else if (product === 'USDT') {
      return USDT;
    } else if (product === 'BCH') {
      return BCH;
    } else if (product === 'XRP') {
      return XRP;
    } else if (product === 'LTC') {
      return LTC;
    }
  }

  _createClass(JoomioComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          handleSubmit = _props.handleSubmit,
          submitting = _props.submitting,
          themeModifier = _props.themeModifier,
          hideKYC = _props.hideKYC,
          customKYCmessage = _props.customKYCmessage,
          walletAddress = _props.walletAddress,
          displayWalletAddress = _props.displayWalletAddress,
          selectedProduct = _props.selectedProduct,
          selectedFiatProduct = _props.selectedFiatProduct,
          getProductByID = _props.getProductByID,
          selectedProductName = _props.selectedProductName,
          selectedFiatProductName = _props.selectedFiatProductName,
          selectedProductSymbol = _props.selectedProductSymbol,
          selectedFiatProductSymbol = _props.selectedFiatProductSymbol,
          clearCryptoBuyAmount = _props.clearCryptoBuyAmount,
          clearFiatPayAmount = _props.clearFiatPayAmount,
          gCryptoBuyAmount = _props.gCryptoBuyAmount,
          gFiatPayAmount = _props.gFiatPayAmount,
          cryptoBuyAmount = _props.cryptoBuyAmount,
          fiatPayAmount = _props.fiatPayAmount,
          useCrypyoBuyCurrency = _props.useCrypyoBuyCurrency,
          toggleBuyCurrency = _props.toggleBuyCurrency,
          verified = _props.verified,
          getQuantityForPrice = _props.getQuantityForPrice,
          prices = _props.prices,
          isFetchingQuote = _props.isFetchingQuote;
        return React.createElement(
          'form',
          {
            className: baseClasses('container', themeModifier),
            onSubmit: handleSubmit
          },
          React.createElement(ProductSelector, { baseClasses }),
          React.createElement(FiatProductSelector, { baseClasses }),
          React.createElement(
            'h2',
            { className: 'cp--title' },
            'Select or enter amount'
          ),
          React.createElement(
            'div',
            { className: 'options-container' },
            {
              true: () => [
                React.createElement(APLabelWithText, {
                  name: 'fiatPayAmount',
                  label: 'You pay',
                  text:
                    selectedFiatProductSymbol &&
                    (isNaN(gFiatPayAmount)
                      ? ''
                      : `${selectedFiatProductSymbol} ${gFiatPayAmount}`),
                  customClass: 'order-entry'
                }),
                React.createElement(APNumberInput, {
                  type: 'text',
                  name: 'cryptoBuyAmount',
                  placeholder: '0',
                  customClass: 'order-entry',
                  label: 'You get',
                  labelInInput: selectedProductSymbol,
                  decimalPlaces: L.get(selectedProduct, ['DecimalPlaces'])
                })
              ],
              false: () => [
                ...(keys =>
                  keys.map((price, i) =>
                    React.createElement(
                      'div',
                      { className: 'buy-options-container', key: i },
                      React.createElement('img', {
                        className: 'cp--icon',
                        src: `${renderProductIcon(selectedProductSymbol)}`
                      }),
                      React.createElement(
                        'h2',
                        { className: 'cp--label' },
                        'For'
                      ),
                      selectedProductSymbol && !!prices[price]
                        ? React.createElement(
                            'div',
                            {
                              className: 'cp--center'
                            },
                            React.createElement('input', {
                              className: 'cp--crypto-amt',
                              disabled: true,
                              value: `~ ${prices[price]}`
                            }),
                            React.createElement(
                              'span',
                              {
                                className: 'cp--crypto-unit'
                              },
                              `${selectedProductSymbol}`
                            )
                          )
                        : React.createElement(Spinner, {}),
                      React.createElement(
                        'h2',
                        { className: 'cp--label' },
                        'You pay'
                      ),
                      React.createElement(
                        'div',
                        {
                          className: 'cp--center',
                          key: `${i}`
                        },
                        React.createElement(Field, {
                          id: `price-${price}`,
                          name: 'iSnapshot',
                          component: 'input',
                          type: 'radio',
                          value: `${i}`,
                          className: 'invisible'
                        }),
                        React.createElement('input', {
                          disabled: true,
                          className: 'cp--text-amt',
                          value: `${price}`
                        }),
                        React.createElement(
                          'span',
                          {
                            className: 'cp--text-unit'
                          },
                          `${selectedFiatProductSymbol}`
                        )
                      ),
                      selectedProduct && selectedFiatProduct
                        ? withKycVerification(verified)([
                          !submitting ?
                            React.createElement(
                              'button',
                              {
                                key: 0,
                                onClick: e => {
                                  e.preventDefault();
                                  document
                                    .getElementById(`price-${price}`)
                                    .click();
                                },
                                className: 'purchase__btn'
                              },
                              //`Buy ${selectedProductName} with ${selectedFiatProductName}`
                              'Buy'
                            ) :
                            React.createElement(Spinner, {key: 0}),
                          React.createElement(
                            'button',
                            {
                              key: 1,
                              id: 'submit-form',
                              className: 'invisible',
                              type: 'submit'
                            },
                            ''
                          )
                        ])
                        : React.createElement(Spinner, {})
                    )
                  ))(!!prices ? Object.keys(prices) : []),
                React.createElement(
                  'div',
                  { className: 'buy-options-container', key:101 },
                  React.createElement('img', {
                    className: 'cp--icon',
                    src: `${renderProductIcon(selectedProductSymbol)}`
                  }),
                  React.createElement('h2', { className: 'cp--label' }, 'For'),
                  !!selectedProductSymbol && !isFetchingQuote
                    ? React.createElement('input', {
                        className: 'cp--text',
                        disabled: true,
                        value: isNaN(gCryptoBuyAmount)
                          ? `~ 0.00000000 ${selectedProductSymbol}`
                          : `~ ${gCryptoBuyAmount} ${selectedProductSymbol}`
                      })
                    : React.createElement(Spinner, {}),
                  React.createElement(
                    'h2',
                    { className: 'cp--label' },
                    'You pay'
                  ),
                  React.createElement(APNumberInput, {
                    type: 'text',
                    name: 'fiatPayAmount',
                    placeholder: 'Amount',
                    className: 'cp--text-input',
                    labelInInput: selectedFiatProductSymbol,
                    decimalPlaces: L.get(selectedFiatProduct, ['DecimalPlaces'])
                  }),
                  selectedProduct && selectedFiatProduct
                    ? withKycVerification(verified)(
                        React.createElement(BuySellOrderButton, {
                          baseClasses: baseClasses,
                          handleSubmit: handleSubmit,
                          submitting: submitting,
                          hideKYC: hideKYC,
                          customKYCmessage: customKYCmessage,
                          product1Name: selectedProductName,
                          product2Name: selectedFiatProductName,
                          disabled: !gCryptoBuyAmount
                        })
                      )
                    : React.createElement(Spinner, {})
                )
              ],
            }[useCrypyoBuyCurrency()]()
          )
          //displayWalletAddress && walletAddress ? walletAddress : 'fetching address',
        );
      }
    }
  ]);

  return JoomioComponent;
})(React.Component);

JoomioComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  displayInstrumentSelector: PropTypes.bool,
  extraInfoComponent: PropTypes.element,
  themeModifier: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  hideKYC: PropTypes.bool,
  customKYCmessage: PropTypes.string
};

JoomioComponent.defaultProps = {
  displayInstrumentSelector: true,
  hideKYC: false,
  amountHeader: 'Amount'
};

JoomioComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default JoomioComponent;
