import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';

var BuySellOrderButtonComponent = function(props, context) {
  var baseClasses = props.baseClasses,
    handleSubmit = props.handleSubmit,
    submitting = props.submitting,
    disabled = props.disabled,
    VerificationRequiredComponent = props.VerificationRequiredComponent,
    hideKYC = props.hideKYC,
    product1Name = props.product1Name,
    product2Name = props.product2Name,
    customKYCmessage = props.customKYCmessage,
    disableKYCLinkUnderReview = props.disableKYCLinkUnderReview;

  return React.createElement(
    APButton,
    {
      className: 'purchase__btn',
      onClick: handleSubmit,
      styleName: styleNames.additive,
      disabled: disabled
    },
    submitting
      ? context.t('Processing...')
      : //        : context.t('Buy {a} with {b}', {
        //            a: product1Name,
        //            b: product2Name
        //          })
        context.t('Buy')
  );
};

BuySellOrderButtonComponent.propTypes = {
  baseClasses: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  VerificationRequiredComponent: PropTypes.func,
  hideKYC: PropTypes.bool,
  customKYCmessage: PropTypes.string
};

BuySellOrderButtonComponent.defaultProps = {
  disableKYCLinkUnderReview: false
};

BuySellOrderButtonComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BuySellOrderButtonComponent;
