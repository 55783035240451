import { connect } from 'react-redux';
import { kycLevelFormSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { validateUserRegistrationJumio } from 'apex-web/lib/redux/actions/kycJumioActions';
import { closeKYC_JumioSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import KYC_JumioIframeComponent from './KYC_JumioIframeComponent';
import { getDisplayLevel } from 'apex-web/lib/helpers/getLevelHelper';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import L from 'lodash';

var mapStateToProps = function mapStateToProps(state) {
  var _kycLevelFormSelector = kycLevelFormSelector(state),
    verificationLevel = _kycLevelFormSelector.verificationLevel,
    kycConfig = _kycLevelFormSelector.kycConfig,
    kyc = _kycLevelFormSelector.kyc,
    alphaPointSessiontoken = _kycLevelFormSelector.alphaPointSessiontoken,
    alphaPointUserID = _kycLevelFormSelector.alphaPointUserID,
    userConfig = _kycLevelFormSelector.userConfig,
    userName = _kycLevelFormSelector.userName,
    processing = _kycLevelFormSelector.processing,
    userEmail = L.get(state.user, ['userInfo', 'Email']);
  var level = getDisplayLevel(kycConfig, verificationLevel + 1);

  return {
    level: level,
    processing: processing,
    alphaPointUserID: alphaPointUserID,
    alphaPointSessiontoken: alphaPointSessiontoken,
    kycConfig: kycConfig,
    userName: userName,
    kyc: kyc,
    userConfig: userConfig,
    userEmail
  };
};

var mapDispatchToProps = {
  validateUserRegistrationJumio: validateUserRegistrationJumio,
  closeKYC_JumioSidePane: closeKYC_JumioSidePane,
  showSnack: showSnack
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KYC_JumioIframeComponent);
