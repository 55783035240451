import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  buyValue,
  sellValue
} from 'apex-web/lib/constants/sendOrder/orderEntryFormConstants';
import { isBuySideBuySellFormSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import { clearBuySellForm } from 'apex-web/lib/redux/actions/orderEntryActions';
import BuySellTabsComponent from './BuySellTabsComponent';

var mapStateToProps = function mapStateToProps(state) {
  return {
    isBuySide: isBuySideBuySellFormSelector(state)
  };
};

var mapDispatchToProps = {
  setBuyValue: function setBuyValue() {
    return change('buySell', 'side', buyValue);
  },
  setSellValue: function setSellValue() {
    return change('buySell', 'side', sellValue);
  },
  clearForm: clearBuySellForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuySellTabsComponent);
