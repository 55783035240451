import L from 'lodash';
import { decrypt } from '../../../helpers/decrypt';

export let validateUserRegistrationJumio = payload =>
  ((apiToken, apiSecret) =>
    fetch('https://app-cryptosx-io.netlify.app/api/v4/initiate/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'User-Agent': 'Cryptosx test/1.0.1',
        Authorization: `Basic ${btoa(
          `${decrypt(apiToken, '12345')}:${decrypt(apiSecret, '12345')}`
        )}`
      },
      body: JSON.stringify({
        customerInternalReference: L.get(payload, ['clientInfo', 'userEmail']),
        userReference: L.get(payload, ['clientInfo', 'alphaPointUserID']),
        // successUrl: 'https://app.cryptosx.io/jumio.html?jumiosuccess=1',
        successUrl: 'https://card.cryptosx.io/didit/api/v1/callback',
        errorUrl: 'https://app.cryptosx.io/jumio.html?jumioerror=1'
      })
    })
      .then(res => res.json())
      .then(resp =>
        Object.assign(resp, {
          RedirectUrl: resp['redirectUrl']
        })
      )
      .catch(console.error))(
    'U2FsdGVkX1/yZBzbIhAqZIi+lj7S4hr/XXd9Qr1yj6KZ1nqfcGpAgnxAvRuhzm4vUgz9jhAYtiU24YHmeQXPLw==',
    'U2FsdGVkX1+RRXflfPZ8ptQU6TH12f0IkhPf2Hr1+gqXiY3TSkat9Ma6hOSWMBmdN2xHYI+VeLgWmiuLBXIpfg=='
  );
