import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import CardImg from '../../../images/custom/cards.png';

import './BuySellMakeTransactionComponent.css';

var classes = getBEMClasses('buy-sell-make-transaction');

// var BuySellMakeTransaction = function BuySellMakeTransaction(props, context) {
//   return React.createElement(
//     'div',
//     { className: classes(null, props.themeModifier) },
//     React.createElement(
//       'h2',
//       { className: classes('title', props.themeModifier) },
//       context.t('Card Purchase')
//     ),
//     React.createElement(
//       'div',
//       { className: classes('content', props.themeModifier) },
//       props.children
//     )
//   );
// };

var BuySellMakeTransaction = function BuySellMakeTransaction(props, context) {
  console.log(props.children);
  return (
    <div className="credit-card-make-transaction">
      <div className={classes('content', props.themeModifier)}>
        {props.children}
        {/* {props.children} */}
      </div>
    </div>
  );
};

BuySellMakeTransaction.propTypes = {
  displayInstrumentSelector: PropTypes.bool,
  extraInfoComponent: PropTypes.element,
  themeModifier: PropTypes.string
};

BuySellMakeTransaction.contextTypes = {
  t: PropTypes.func.isRequired
};

export default BuySellMakeTransaction;
