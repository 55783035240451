import BEMHelper from 'react-bem-helper';
import pickBy from 'lodash/pickBy';

var _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? function(obj) {
        return typeof obj;
      }
    : function(obj) {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj;
      };

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  } else {
    return Array.from(arr);
  }
}

export var getBEMClasses = function getBEMClasses() {
  var _Array$prototype;

  for (
    var _len = arguments.length, classes = Array(_len), _key = 0;
    _key < _len;
    _key++
  ) {
    classes[_key] = arguments[_key];
  }

  classes = (_Array$prototype = Array.prototype).concat
    .apply(_Array$prototype, _toConsumableArray(classes))
    .filter(function(item) {
      return item;
    });

  var BEMClasses = classes.map(function(className) {
    return new BEMHelper({
      name: className,
      outputIsString: true
    });
  });
  return function(element, modifier) {
    return BEMClasses.map(function(BEMClass) {
      return BEMClass(element, processModifiers(modifier));
    }).join(' ');
  };
};

function processModifiers(modifiers) {
  return isObject(modifiers) ? pickBy(modifiers) : modifiers;
}

function isObject(obj) {
  return obj === Object(obj) && !Array.isArray(obj);
}

export var addModifier = function addModifier(modifiers, newModifier) {
  var result = void 0;

  if (Array.isArray(modifiers)) {
    result = [newModifier].concat(_toConsumableArray(modifiers));
  } else if (typeof modifiers === 'string') {
    result = [newModifier, modifiers];
  } else if (
    (typeof modifiers === 'undefined' ? 'undefined' : _typeof(modifiers)) ===
    'object'
  ) {
    result = modifiers;
    result[newModifier] = true;
  }

  return result;
};
