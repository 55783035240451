import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import WalletRowContainer from './WalletRowContainer';
import { DepositReferenceIdModalContainer } from 'apex-web/lib/components/Retail/RetailSidePanes/FiatDeposit';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

import './WalletRowList.css';

var walletRowListClasses = getBEMClasses('wallet-row-list');

var WalletRowListComponent = function WalletRowListComponent(props, context) {
  if (props.fetching) {
    return React.createElement(Spinner, { customClass: walletRowListClasses });
  }

  // Exclude "USDT_GX_lock" from unfunded accounts array list
  // USDT_GX_lock ProductId=46
  const filteredProducts = props.products.filter(
    product => product.ProductId !== 46
  );

  var items = filteredProducts.map(function(item) {
    return React.createElement(WalletRowContainer, {
      key: item.ProductId,
      productId: item.ProductId,
      detailsLink: props.detailsLink
    });
  });

  return React.createElement(
    'div',
    { className: walletRowListClasses() },
    items,
    React.createElement(DepositReferenceIdModalContainer, null)
  );
};

WalletRowListComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WalletRowListComponent;
