import React from 'react';
import PropTypes from 'prop-types';
import APButton from 'apex-web/lib/components/common/APButton';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';

var Button = props => {
  let baseClasses = props.baseClasses,
    onClick = props.onClick,
    label = props.label,
    disabled = props.disabled;

  return React.createElement(
    'div',
    { className: baseClasses('confirm-button-container') },
    React.createElement(
      APButton,
      {
        customClass: baseClasses(),
        onClick,
        styleName: styleNames.additive,
        disabled
      },
      label
    )
  );
};

export default Button;
