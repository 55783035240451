import React from 'react';
import PropTypes from 'prop-types';
import APRadio from 'apex-web/lib/components/common/APRadio/APRadio';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import { convertIncrementToIntDecimalPlaces } from 'apex-web/lib/helpers/decimalPlaces/decimalPlacesHelper';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var BuySellCustomRowComponent = (function(_React$Component) {
  _inherits(BuySellCustomRowComponent, _React$Component);

  function BuySellCustomRowComponent() {
    _classCallCheck(this, BuySellCustomRowComponent);

    return _possibleConstructorReturn(
      this,
      (
        BuySellCustomRowComponent.__proto__ ||
        Object.getPrototypeOf(BuySellCustomRowComponent)
      ).apply(this, arguments)
    );
  }

  _createClass(BuySellCustomRowComponent, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          synchronizeQuantityWithPrice = _props.synchronizeQuantityWithPrice,
          synchronizePriceWithQuantity = _props.synchronizePriceWithQuantity,
          setAmount = _props.setAmount,
          activeAmount = _props.activeAmount,
          themeModifier = _props.themeModifier,
          formObj = _props.formObj,
          isBuySide = _props.isBuySide,
          baseClasses = _props.baseClasses,
          selectedInstrument = _props.selectedInstrument;

        var arrow = React.createElement(
          'p',
          { className: baseClasses('arrow') },
          isBuySide ? '→' : '←'
        );

        return React.createElement(
          'div',
          {
            className: baseClasses('amount-row', 'two-inputs-layout'),
            onClick: function onClick() {
              return setAmount('custom');
            }
          },
          React.createElement(
            'div',
            { className: baseClasses('first-in-row') },
            React.createElement(APRadio, {
              name: 'amountRadio',
              id: 'custom',
              value: 'custom'
            })
          ),
          React.createElement(
            'div',
            null,
            React.createElement(APNumberInput, {
              name: 'price',
              customClass: baseClasses(),
              classModifiers:
                (activeAmount === 'custom' ? 'active' : 'disable') +
                ' ' +
                themeModifier,
              step: selectedInstrument.PriceIncrement,
              decimalPlaces: convertIncrementToIntDecimalPlaces(
                selectedInstrument.PriceIncrement
              ),
              customChange: function customChange(value) {
                synchronizeQuantityWithPrice(
                  value,
                  'buySell',
                  'quantity',
                  formObj
                );
              }
            })
          ),
          arrow,
          React.createElement(
            'div',
            { className: baseClasses('last-in-row') },
            React.createElement(APNumberInput, {
              name: 'quantity',
              step: selectedInstrument.QuantityIncrement,
              customClass: baseClasses(),
              classModifiers:
                (activeAmount === 'custom' ? 'active' : 'disable') +
                ' ' +
                themeModifier,
              decimalPlaces: convertIncrementToIntDecimalPlaces(
                selectedInstrument.QuantityIncrement
              ),
              customChange: function customChange(value) {
                synchronizePriceWithQuantity(
                  value,
                  'buySell',
                  'price',
                  formObj
                );
              }
            })
          )
        );
      }
    }
  ]);

  return BuySellCustomRowComponent;
})(React.Component);

BuySellCustomRowComponent.propTypes = {
  isBuySide: PropTypes.bool.isRequired,
  formObj: PropTypes.object.isRequired,
  synchronizeQuantityWithPrice: PropTypes.func.isRequired,
  synchronizePriceWithQuantity: PropTypes.func.isRequired,
  selectedInstrument: PropTypes.object.isRequired,
  themeModifier: PropTypes.string
};

export default BuySellCustomRowComponent;
