import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FiatDepositFormComponent from './FiatDepositFormComponent';
import { createDepositTicket, fetchProductDepositTemplate, fetchAllProductDepositTemplates } from 'apex-web/lib/redux/actions/depositActions';
import { openModal, MODAL_TYPES } from 'apex-web/lib/redux/actions/modalActions';
import { kycVerificationLevelsSelector } from 'apex-web/lib/redux/selectors/kycLevelsSelectors';
import { closeSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { validateDepositTicketPayload } from 'apex-web/lib/helpers/createFiatDepositHelper';

var mapStateToProps = function mapStateToProps(state) {
  var _kycVerificationLevel = kycVerificationLevelsSelector(state),
      disableDeposit = _kycVerificationLevel.disableDeposit;

  return {
    disableDeposit: disableDeposit,
    depositInfo: state.deposit,
    products: state.apexCore.product
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    confirmDeposit: createDepositTicket,
    verificationOnClick: function verificationOnClick() {
      return dispatch(closeSidePane());
    },
    selectDepositProduct: function selectDepositProduct(productId) {
      return dispatch(fetchAllProductDepositTemplates(productId));
    },
    selectDepositTemplate: function selectDepositTemplate(productId, templateType) {
      return dispatch(fetchProductDepositTemplate(productId, templateType));
    }
  };
};

var FiatDepositForm = reduxForm({
  form: 'createFiatDeposit',
  onSubmit: function onSubmit(payload, dispatch, _ref) {
    var _ref$depositInfo = _ref.depositInfo,
        template = _ref$depositInfo.template,
        templateInfo = _ref$depositInfo.templateInfo,
        registeredFields = _ref.registeredFields;
    return dispatch(openModal(MODAL_TYPES.CONFIRM_FIAT_DEPOSIT, {
      payload: validateDepositTicketPayload(payload, registeredFields),
      template: template,
      templateInfo: templateInfo
    }));
  }
})(FiatDepositFormComponent);

export default connect(mapStateToProps, mapDispatchToProps)(FiatDepositForm);