import axios from 'axios';
import { closeWithdrawSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';
import { showSnack } from 'apex-web/lib/redux/actions/snackbarActions';
import {
  submittingWithdraw,
  receiveWithdrawTicket,
  getWithdrawTickets
} from 'apex-web/lib/redux/actions/withdrawActions';

export var redeemGiftCardByWithdrawTicket = payload => dispatch => {
  console.log('giftCard payload', payload);
  axios
    .post(
      'http://ec2-52-74-231-166.ap-southeast-1.compute.amazonaws.com:8080/redeemGiftCardByWithdrawTicket',
      {
        giftCard: payload.templateForm,
        user: payload.user,
        withdrawStatus: payload.withdrawStatus
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer vyd3GgAKFu4ZhnG3uR4zdWEYRBfaxtxPFVq7MpFekkHZaMbXpccJF2k9wx4u7wRNVWgLFjAUrXBrx67H5hdAsUPGZRzJFEperEQJbHAHvrUALk7U2MRj36Gkmgrdqv4M4CGyU5b3jvNnsvhJ6kzBSpNfgayHdmQw8a66vz37GNmk3SMqRMZZVgExfqeB7Ts8Jcav7TGqhvxFRHjwcvC8rfJ97mArG6GvC8AMDjrWWmsNPMGPZXryeBBJUDrST5ez'
        }
      }
    )
    .then(res => {
      console.log('redeemGiftCardByWithdrawTicket Res', res.data);
      dispatch(closeWithdrawSidePane());
      dispatch(
        showSnack({
          id: 'withdrawGiftCardSuccessSnack',
          // context.t("Your withdraw has been successfully added")
          text: `Your ${payload.templateForm.giftCardAmount} USD ${
            payload.templateForm.giftCardType
          } Card withdraw has been successfully added, we will email the card info to you soon.`,
          type: 'success'
        })
      );

      dispatch(receiveWithdrawTicket(payload.withdrawStatus));
    })
    .catch(err => {
      console.error('redeemGiftCardByWithdrawTicket err', err);
      dispatch(
        showSnack({
          id: 'withdrawGiftCardFailedSnack',
          // context.t("Your withdraw has been successfully added")
          text: `Your ${payload.templateForm.giftCardAmount} USD ${
            payload.templateForm.giftCardType
          } Card withdraw failed. Please contact our staff as soon as possible to cancel the withdraw ticket.`,
          type: 'warning'
        })
      );
    });
};
