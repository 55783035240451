import apex from 'apex-web/lib/apex';
import {
  setUserNotAuthenticated
} from 'apex-web/lib/redux/actions/authActions'

export var conditionalLogout = function logout(resolve, reject, waitBeforeLogout) {
  return function (dispatch, getState) {
    var wasAuthenticatedBefore = getState().auth.isAuthenticated;
    var action = setUserNotAuthenticated(wasAuthenticatedBefore);
    Promise.resolve()
      .then(() => {
        let sessionToken = localStorage.getItem('token');
        if (typeof sessionToken === 'string') {
          reject(new Error('You previously logged in with stale data. Please re-login again.'));
          return (
            Promise.resolve()
              .then(waitBeforeLogout)
              .then(() => Promise.all([
                apex.connection.RPCPromise('LogOut', {}),
                localStorage.removeItem('tradingServer'),
                dispatch(action)
              ]))
              .then(() => {})
          );
        } else {
          resolve();
        }
        return Promise.resolve();
      })
      .catch(reject);
  };
};