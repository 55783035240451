// import React from 'react';
// import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import ProductSelector from './Component';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { isBuySideBuySellFormSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import { selectProduct } from '../../../../redux/action/productAction';
import { selectedProductSelector } from '../../../../redux/selector/productSelector';

let mapStateToProps = state => {
  // const [t, i18n] = useTranslation('common');
  // {t('navBar.Card Purchase')}
  var label = 'I am buying';
  return {
    products: state.apexCore.product.products.filter(
      ({ ProductType, Product }) =>
        ProductType === 'CryptoCurrency' &&
        ['BTC', 'ETH', 'USDT'].some(_ => Product === _)
    ),
    selectedProduct: selectedProductSelector(state),
    label
  };
};
let mapDispatchToProps = dispatch => ({
  onSelectProduct: productID => selectProduct(productID)(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSelector);
