import React from 'react';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import CardImg from '../images/custom/cards.png';
import SuccessImg from '../images/custom/success.png';
import './JoomioPage.css';

const classes = getBEMClasses('eotc-buy-sell-layout');

const SuccessPage = () => {
  return (
    <React.Fragment>
      <div>
        <div className={classes('accent-bar')} />
        <div className={classes('container')}>
          <div className={classes('col', ['lg'])}>
            <div className="title-container">
              <div className="col-left">
                <h2>Card Purchase</h2>
              </div>
              {/* <div className="col-right">
                <img className="card-img" src={CardImg} />
              </div> */}
            </div>
            <div className="cp-container-dark">
              <img className="cp-success-img" src={SuccessImg} />
              <h1 className="cp-success-header">
                Thank you for your purchase!
              </h1>
              <h3 className="cp-success-text">
                This is to confirm that your credit has been processed, funds
                will be deposited into your exchange wallet within{' '}
                <strong>48 hours.</strong>
              </h3>
              <h3 className="cp-success-text">
                If there are any Errors, please Contact us by E-mail on support@greenx.io
              </h3>
            </div>
          </div>
        </div>
        <div className={classes('footer')}>
          <PageFooterComponent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withAuthentication(SuccessPage);
