import React from 'react';
import PropTypes from 'prop-types';
import PaddedDecimal from 'apex-web/lib/components/common/PaddedDecimal/PaddedDecimal';
import { isFirefox } from 'apex-web/lib/helpers/browserHelper';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import CancelAPButton from 'apex-web/lib/components/common/CancelAPButton';
import Spinner from 'apex-web/lib/components/common/Spinner/Spinner';

var orderBookClasses = getBEMClasses('orderbook');
var flexTable = getBEMClasses('flex-table');
var flexTableColumn = flexTable('column');
var cancelClassName = flexTableColumn + ' ' + orderBookClasses('cancel-btn');

var Level2DataTableComponent = function Level2DataTableComponent(props) {
  var cancelOrders = props.cancelOrders,
      onPriceClick = props.onPriceClick,
      onQuantityClick = props.onQuantityClick,
      level2Data = props.level2Data,
      classModifier = props.classModifier,
      fetching = props.fetching;


  var rowClassName = flexTable('row') + ' ' + orderBookClasses('order-row', classModifier);
  var priceClassName = flexTableColumn + ' ' + orderBookClasses('table-price', classModifier);
  var quantityClassName = flexTableColumn + ' ' + orderBookClasses('table-qty', classModifier);
  var mySizeClassName = flexTableColumn + ' ' + orderBookClasses('table-my-size', isFirefox() ? 'firefox' : '');

  if (fetching) {
    return React.createElement(Spinner, { customClass: orderBookClasses, classModifiers: classModifier });
  } else {
    return level2Data.map(function (data, index) {
      return React.createElement(
        'div',
        { key: data.Price, className: rowClassName },
        React.createElement(
          'div',
          {
            'data-price': data.Price,
            onClick: onPriceClick,
            className: priceClassName },
          React.createElement(PaddedDecimal, {
            bemMod: classModifier,
            value: data.Price,
            decimals: props.priceDecimalPlaces
          })
        ),
        React.createElement(
          'div',
          {
            'data-quantity': data.Quantity,
            'data-index': index,
            onClick: onQuantityClick,
            className: quantityClassName },
          React.createElement(PaddedDecimal, {
            value: data.Quantity,
            decimals: typeof props.quantityDecimalPlaces === 'number' ? Math.min(props.quantityDecimalPlaces, 5) : props.quantityDecimalPlaces
          })
        ),
        React.createElement(
          'div',
          { className: mySizeClassName },
          //index
          data.QuantitySum && React.createElement(
            'span',
            null,
            React.createElement(PaddedDecimal, {
              value: data.QuantitySum,
              decimals: typeof props.quantityDecimalPlaces === 'number' ? Math.min(props.quantityDecimalPlaces, 5) : props.quantityDecimalPlaces
            })
          )
          /*data.MySize && React.createElement(
            'span',
            null,
            React.createElement(PaddedDecimal, {
              value: data.MySize,
              decimals: props.quantityDecimalPlaces
            })
          )*/
        ),
        React.createElement(
          'div',
          { className: cancelClassName },
          data.MySize && React.createElement(CancelAPButton, {
            onClick: function onClick() {
              if (data.OrderIds.length) {
                cancelOrders(data.OrderIds);
              } else {
                cancelOrders([data.OrderId]);
              }
            },
            text: '',
            customClass: orderBookClasses()
          })
        )
      );
    });
  }
};

Level2DataTableComponent.propTypes = {
  cancelOrders: PropTypes.func.isRequired,
  onPriceClick: PropTypes.func.isRequired,
  onQuantityClick: PropTypes.func.isRequired,
  level2Data: PropTypes.array.isRequired,
  classModifier: PropTypes.string,
  fetching: PropTypes.bool
};

export default Level2DataTableComponent;