import React, { Component } from 'react';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { initApex, setGateway } from 'apex-web/lib/apex';
import {
  connectionOpened,
  connectionClosed
} from 'apex-web/lib/redux/actions/wsConnectionActions';
import { getQueryParam } from './helpers/queryParamsHelper';
import { onInit } from 'apex-web/lib/redux/actions/lifeCycleActions';
import StandAloneLayout from './layouts/StandAloneLayout/StandAloneLayout';
import './styles/theme/dark.css';
import SignupPage from './pages/SignupPage';
import ProblemLoggingInPage from './pages/ProblemLoggingInPage';
import DepositConfirmation from 'apex-web/lib/components/DepositConfirmation/DepositConfirmationModalContainer';
import Snackbar from './components/APSnackbar/APSnackbarContainer';
import TwoFactor from 'apex-web/lib/components/TwoFactorAuthForm/TwoFactorAuthFormContainer';
import SidePane from './components/common/SidePane/SidePaneContainer';
import { emailLinkRoutes } from 'apex-web/lib/helpers/emailLinksHelper';
import EmailLinks from 'apex-web/lib/layouts/EmailLinksLayout.js';
import InteriorPage from './pages/InteriorPage';
import StandaloneLoginFormContainer from './components/Login/StandaloneLoginFormContainer';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import SimpleExchangePage from './pages/Exchange/SimpleExchangePage';
import ExchangePageOut from './pages/Exchange/ExchangePageOut';
import config from './config';
import Require2FA from 'apex-web/lib/components/2FA/TwoFactorAuthContainer';
import Enable2FA from 'apex-web/lib/components/EnableTwoFactorAuth/StandaloneEnable2FAModalContainer';
import EmailPrompt from './components/common/EXEmailPrompt';
import SignupPage2uChat from './pages/2uChat/SignupPage';
import StandaloneLoginFormContainer2uChat from './components/2uChat/Login/StandaloneLoginFormContainer';
import redirectIfAuthorized2uChat from './hocs/2uChat/redirectIfAuthorized'
import Logout2uChat from './pages/2uChat/LogoutPage'
import CampaignSignUpPage from './pages/CampaignSignUpPage';

class App extends Component {
  constructor(props) {
    super(props);
    setGateway(getGateway());
    initApex(this.props.connectionOpened, this.props.connectionClosed);
    const AffiliateTag = getQueryParam('aff');
    if (AffiliateTag) {
      window.localStorage.setItem('affiliateTag', AffiliateTag);
    }
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <React.Fragment>
        <div className="App fluid">
          {/* This is Website ID, for AlphaPoint deploy use  */}
          {/* <span
            style={{
              color: 'white',
              fontSize: '12px',
              zIndex: '65535',
              textAlign: 'right',
              marginRight: '7px',
              fontFamily: 'Now-Bold'
            }}>
            app-v333
          </span> */}

          <Switch>
            <Route
              path="/version"
              render={() => (
                <div>
                  <p>v3implementation-template: {packageJson.version}</p>
                  <p>apex-web: {packageJson.dependencies['apex-web']}</p>
                </div>
              )}
            />
            <Route path={'/2uchat/login'} component={redirectIfAuthorized2uChat(StandaloneLoginFormContainer2uChat)} />
            <Route path={'/2uchat/signup'} component={redirectIfAuthorized2uChat(SignupPage2uChat)} />
            <Route path={'/2uchat/logout'} component={Logout2uChat} />
            <Route path={'/wenew/registration'} component={CampaignSignUpPage} />
            <Route
              exact
              path={'/'}
              component={redirectIfAuthorized(
                StandaloneLoginFormContainer,
                process.env.REACT_APP_DEFAULT_PATH
              )}
            />
            {emailLinkRoutes(pathname, EmailLinks)}
            <Route
              path={'/login'}
              component={redirectIfAuthorized(
                StandaloneLoginFormContainer,
                process.env.REACT_APP_DEFAULT_PATH
              )}
            />
            <Route path={'/signup'} component={SignupPage} />
            <Route
              path={'/problem-logging-in'}
              component={ProblemLoggingInPage}
            />
            <Route
              path={'/twofactorauth'}
              render={() => (
                <StandAloneLayout>
                  <TwoFactor />
                </StandAloneLayout>
              )}
            />
            <Route path={'/enabletwofactorauth'} render={() => <Enable2FA />} />
            {config.SimpleExchange.active && (
              <Route
                path={config.SimpleExchange.route}
                component={ExchangePageOut}
              />
            )}
            {/* passing props.location prevents Blocked Re-renders: https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking */}
            <InteriorPage location={this.props.location} />
          </Switch>
        </div>
        <Snackbar />
        <SidePane />
        <DepositConfirmation />
        <Require2FA />
        <EmailPrompt />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  onInit,
  connectionOpened,
  connectionClosed
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(App)
);
