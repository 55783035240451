var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

import React from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import {
  required,
  email,
  lessThanValue,
  validateReceiverEmail
} from 'apex-web/lib/helpers/formValidations';
import SendAddressContainer from '../SendAddress/SendAddressContainer';
import VerificationRequiredContainer from 'apex-web/lib/components/VerificationRequired/VerificationRequiredContainer';
import APNumberInput from 'apex-web/lib/components/common/APNumberInput';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APLabelWithText from 'apex-web/lib/components/common/APLabelWithText';
import { product as ProductPropTypes } from 'apex-web/lib/propTypes/sendReceiveComponent';
import './SendForm.css';

var bemClasses = getBEMClasses('send-form');

var SendForm = (function(_React$Component) {
  _inherits(SendForm, _React$Component);

  function SendForm() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SendForm);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SendForm.__proto__ || Object.getPrototypeOf(SendForm)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.lessThanBalance = function(value) {
        var balance = _this.props.balance;

        if (balance === 0) return _this.context.t('0 balance available');
        return lessThanValue(balance, value);
      }),
      (_this.checkUserEmail = function(value) {
        var userEmail = _this.props.userEmail;

        return validateReceiverEmail(value, userEmail);
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  _createClass(SendForm, [
    {
      key: 'render',
      value: function render() {
        var _props = this.props,
          _props$product = _props.product,
          name = _props$product.ProductFullName,
          symbol = _props$product.ProductSymbol,
          decimalPlaces = _props$product.decimalPlaces,
          handleSubmit = _props.handleSubmit,
          invalid = _props.invalid,
          submitting = _props.submitting,
          onSubmit = _props.onSubmit,
          useExternalAddress = _props.useExternalAddress,
          toggleUseExternalAddress = _props.toggleUseExternalAddress,
          balance = _props.balance,
          product = _props.product,
          fee = _props.fee,
          total = _props.total,
          showDetails = _props.showDetails,
          disableWithdraw = _props.disableWithdraw,
          verificationOnClick = _props.verificationOnClick,
          allowTransfer = _props.allowTransfer,
          withdrawError = _props.withdrawError,
          verificationLevelConfig = _props.verificationLevelConfig;

        return React.createElement(
          'form',
          { onSubmit: handleSubmit(onSubmit), className: bemClasses() },
          React.createElement(
            'section',
            { className: bemClasses('send-to') },
            React.createElement(
              'header',
              { className: bemClasses('source') },
              React.createElement(
                'div',
                {
                  className: bemClasses('source-item', {
                    selected: useExternalAddress
                  }),
                  onClick: function onClick() {
                    !useExternalAddress && toggleUseExternalAddress();
                  }
                },
                // this wording changed for better understanding
                this.context.t('External Transfer')
              ),
              allowTransfer &&
                React.createElement(
                  'div',
                  {
                    className: bemClasses('source-item', {
                      selected: !useExternalAddress
                    }),
                    onClick: function onClick() {
                      useExternalAddress && toggleUseExternalAddress();
                    }
                  },
                  // this wording changed for better understanding
                  this.context.t('Internal Transfer')
                )
            )
          ),
          disableWithdraw
            ? React.createElement(VerificationRequiredContainer, {
                disabled: disableWithdraw,
                onClick: verificationOnClick
              })
            : React.createElement(
                React.Fragment,
                null,
                useExternalAddress
                  ? React.createElement(SendAddressContainer, {
                      product: product,
                      balance: balance,
                      verificationLevelConfig: verificationLevelConfig
                    })
                  : React.createElement(
                      React.Fragment,
                      null,
                      React.createElement(
                        'p',
                        { className: bemClasses('label-text') },
                        this.context.t('Recipient’s Email Address')
                      ),
                      React.createElement(APInput, {
                        name: 'ReceiverUsername',
                        customClass: bemClasses(),
                        validate: [required, email, this.checkUserEmail]
                      })
                    ),
                (!withdrawError || !useExternalAddress) &&
                  React.createElement(
                    React.Fragment,
                    null,
                    React.createElement(
                      'p',
                      { className: bemClasses('label-text') },
                      this.context.t('Amount of {name} to Send', { name: name })
                    ),
                    React.createElement(
                      'section',
                      { className: bemClasses('amounts') },
                      React.createElement(APNumberInput, {
                        name: 'Amount',
                        customClass: bemClasses(),
                        labelInInput: symbol,
                        decimalPlaces: decimalPlaces,
                        validate: [required, this.lessThanBalance]
                      })
                    )
                  ),
                !showDetails &&
                  React.createElement(
                    'section',
                    { className: bemClasses('info-items') },
                    React.createElement(APLabelWithText, {
                      label: this.context.t('Current Balance:'),
                      text: balance,
                      customClass: bemClasses()
                    }),
                    React.createElement(APLabelWithText, {
                      label: this.context.t('Transaction Fee:'),
                      text: fee !== undefined ? fee : '-',
                      customClass: bemClasses()
                    }),
                    React.createElement(APLabelWithText, {
                      label: this.context.t('Total Amount:'),
                      text: total !== undefined ? total + ' ' + symbol : '-',
                      customClass: bemClasses()
                    })
                  ),
                !useExternalAddress &&
                  React.createElement(
                    'section',
                    { className: bemClasses('note') },
                    React.createElement(
                      'p',
                      { className: bemClasses('label-text') },
                      this.context.t('Add a Note '),
                      React.createElement(
                        'em',
                        null,
                        this.context.t('(Optional)')
                      )
                    ),
                    React.createElement(APInput, {
                      name: 'Notes',
                      rows: 3,
                      customClass: bemClasses()
                    })
                  ),
                (!withdrawError || !useExternalAddress) &&
                  React.createElement(
                    APButton,
                    {
                      type: 'submit',
                      customClass: bemClasses(),
                      styleName: 'additive',
                      disabled: invalid || submitting
                    },
                    this.context.t('Send'),
                    ' ',
                    name
                  )
              )
        );
      }
    }
  ]);

  return SendForm;
})(React.Component);

SendForm.propTypes = {
  product: ProductPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  useExternalAddress: PropTypes.bool.isRequired,
  balance: PropTypes.number.isRequired,
  toggleUseExternalAddress: PropTypes.func.isRequired,
  allowTransfer: PropTypes.bool.isRequired,
  fee: PropTypes.number,
  total: PropTypes.number,
  disableWithdraw: PropTypes.bool,
  verificationOnClick: PropTypes.func
};

SendForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendForm;
