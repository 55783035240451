import React from 'react';
import ApexChartContainer from './ApexChartContainer';
import TVChartContainer from './TVChartContainer';
import LightWeightChartContainer from 'apex-web/lib/components/LightWeightCharts/LightWeightChartContainer.js';
import config from 'apex-web/lib/config';

var chartOptions = {
  APEX: React.createElement(ApexChartContainer, null),
  TradingView: React.createElement(TVChartContainer, null),
  LightWeight: React.createElement(LightWeightChartContainer, null)
};

var PriceChartContainer = function PriceChartContainer(props) {
  var chart = config.TradingLayout.chart;
  if (props.chart) chart = props.chart;
  return chartOptions[chart];
};

export default PriceChartContainer;
