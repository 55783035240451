import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import APButton from 'apex-web/lib/components/common/APButton';
import {
  required,
  email,
  matchingPassword,
  validatePassword
} from 'apex-web/lib/helpers/formValidations';
import { renderFormInputs } from 'apex-web/lib/helpers/formGeneratorHelper';
import path from 'apex-web/lib/helpers/path';
import passwordScore from 'apex-web/lib/helpers/passwordScore';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';
import APSelect from 'apex-web/lib/components/common/APSelect';
import CountryList from './AllCountry';
import APCheckbox from 'apex-web/lib/components/common/APCheckbox/APCheckbox';
import { type } from 'os';

// import 'apex-web/lib/styles/components/common/StandaloneForm.css';
// import './SimpleSignupFormComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('standalone-form');
var signupFormClasses = getBEMClasses('signup-form');

var SimpleSignupFormComponent = (function(_Component) {
  _inherits(SimpleSignupFormComponent, _Component);

  function SimpleSignupFormComponent() {
    // this is for translation in Country selete box
    const current_language = localStorage.getItem('language');

    if (current_language == 'zh') {
      for (let index = 0; index < CountryList.length; index++) {
        const element = CountryList[index];
        if (element.label == 'Country') {
          CountryList[index].label = '国家/地区 : 中国';
        }
      }
    }
    // end

    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SimpleSignupFormComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SimpleSignupFormComponent.__proto__ ||
          Object.getPrototypeOf(SimpleSignupFormComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.getPasswordStrength = function() {
        var _this2 = _this,
          context = _this2.context;

        var score = passwordScore(_this.props.passwordValue);
        var passwordStrengthValues = {
          0: context.t('Very Weak'),
          1: context.t('Weak'),
          2: context.t('Medium'),
          3: context.t('Strong'),
          4: context.t('Very Strong')
        };

        return passwordStrengthValues[score];
      }),
      (_this.handleChange = function() {
        if (_this.props.errorMsg) {
          _this.props.clearSignupError();
        }
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }
  _createClass(SimpleSignupFormComponent, [
    {
      key: 'render',
      value: function render() {
        var context = this.context;
        var _props = this.props,
          handleSubmit = _props.handleSubmit,
          errorMsg = _props.errorMsg,
          submitting = _props.submitting,
          pristine = _props.pristine,
          passwordValue = _props.passwordValue,
          invalid = _props.invalid,
          done = _props.done,
          useEmailAsUsername = _props.useEmailAsUsername,
          additionalFields = _props.additionalFields;

        // Terms & conditions and privacy policy checkbox label
        const tc_label = (
          <>
            {context.t('I have read and accept the ')}
            <a
              href="https://www.green-x.io/terms-and-conditions"
              target="_blank">
              {context.t('Terms & Conditions')}
            </a>
            &nbsp;
            {context.t('and')}
            &nbsp;
            <a href="https://www.green-x.io/privacy-policy" target="_blank">
              {context.t('Privacy Policy')}
            </a>.
          </>
        );

        if (done) {
          return React.createElement(
            'p',
            { className: '' + baseClasses('success') },
            context.t(
              'Thanks for signing up, please check your inbox for a confirmation email'
            )
          );
        } else {
          return React.createElement(
            'form',
            {
              onSubmit: handleSubmit,
              className: ''
            },
            React.createElement(
              'div',
              { className: signupFormClasses('container') },
              !useEmailAsUsername &&
                React.createElement(
                  'div',
                  { className: 'form-group' },
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'username',
                    className: 'form-control',
                    placeholder: 'username',
                    label: context.t('Username'),
                    validate: [required, noWhiteSpace],
                    // customClass: signupFormClasses(),
                    onChange: this.handleChange
                  })
                ),
              React.createElement(APInput, {
                type: 'text',
                name: 'email',
                placeholder: context.t('Valid Email Address'),
                className: 'form-control',
                label: context.t('Valid Email Address'),
                validate: [required, email],
                // customClass: signupFormClasses(),
                onChange: this.handleChange
              }),
              // for new Gov ID info input field
              React.createElement(
                'span',
                { className: 'Gov_ID_span' },
                context.t('(Per Government ID)')
              ),
              React.createElement(
                'div',
                { className: 'row' },
                React.createElement(
                  'div',
                  { className: 'col-sm-6' },
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'GovID_FirstName',
                    placeholder: context.t('First Name'),
                    className: 'form-control',
                    label: context.t('First Name'),
                    validate: [required],
                    onChange: this.handleChange
                  })
                ),
                React.createElement(
                  'div',
                  { className: 'col-sm-6' },
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'GovID_LastName',
                    placeholder: context.t('Last Name'),
                    className: 'form-control',
                    label: context.t('Last Name'),
                    validate: [required],
                    onChange: this.handleChange
                  })
                ),
                React.createElement(
                  'div',
                  { className: 'col-12' },
                  React.createElement(APSelect, {
                    type: 'text',
                    name: 'GovID_Country',
                    placeholder: context.t('Country'),
                    className: 'form-control',
                    label: context.t('Country'),
                    multiple: false,
                    required: true,
                    options: CountryList,
                    onChange: this.handleChange
                  })
                )
              ),
              // field end
              React.createElement(
                'div',
                { className: ' ' },
                React.createElement(APPasswordInput, {
                  type: 'password',
                  name: 'password',
                  placeholder: context.t('Password'),
                  className: 'form-control',
                  label: context.t('Password'),
                  validate: [required],
                  // info: passwordValue && this.getPasswordStrength(),
                  // customClass: signupFormClasses(),
                  onChange: this.handleChange
                })
                // React.createElement("p", { className: "form-text" } ,'Contains at least 8 characters, 1 number, 1 capital letter'),
              ),
              React.createElement(
                'div',
                { className: '' },
                React.createElement(APPasswordInput, {
                  type: 'password',
                  name: 'matchingPassword',
                  placeholder: context.t('Retype Password'),
                  label: context.t('Retype Password'),
                  // classes: "signup",
                  validate: [required, matchingPassword, validatePassword],
                  customClass: signupFormClasses(),
                  onChange: this.handleChange
                })
              ),
              renderFormInputs(additionalFields, signupFormClasses(), context),
              errorMsg &&
                React.createElement(
                  'p',
                  {
                    className:
                      baseClasses('error') + ' ' + signupFormClasses('error')
                  },
                  errorMsg
                )
            ),
            React.createElement(APCheckbox, {
              name: 'tc-checkbox',
              type: 'checkbox',
              id: 'tc_checkbox',
              label: tc_label,
              required: true,
              validate: [required],
              autoFocus: true
            }),
            React.createElement(
              APButton,
              {
                type: 'submit',
                disabled: pristine || submitting || invalid,
                className: 'ap-button__btn login-btn mt-4',
                customClass: baseClasses() + ' ' + signupFormClasses(),
                styleName: styleNames.additive
              },
              submitting ? context.t('Processing...') : context.t('Sign Up')
            )
          );
        }
      }
    }
  ]);

  return SimpleSignupFormComponent;
})(Component);

SimpleSignupFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

SimpleSignupFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string,
  search: PropTypes.string,
  shouldRedirect: PropTypes.bool
};

SimpleSignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SimpleSignupFormComponent;
