import { connect } from 'react-redux';
import ProductSelector from './Component';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import { isBuySideBuySellFormSelector } from 'apex-web/lib/redux/selectors/buySellSelectors';
import { selectFiatProduct } from '../../../../redux/action/productAction';
import { selectedFiatProductSelector } from '../../../../redux/selector/productSelector';

let mapStateToProps = state => {
  var label = 'With';
  return {
    products: state.apexCore.product.products.filter(
      ({ ProductType }) => ProductType === 'NationalCurrency'
    ),
    selectedProduct: selectedFiatProductSelector(state),
    label
  };
};
let mapDispatchToProps = dispatch => ({
  onSelectProduct: productID => selectFiatProduct(productID)(dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSelector);
