import { connect } from 'react-redux';
import { get } from 'lodash';
import FiatSidePaneComponent from './FiatSidePaneComponent';
import { createWithdrawTicket, getWithdrawFee as _getWithdrawFee } from 'apex-web/lib/redux/actions/withdrawActions';

import { positionByProductIdSelector } from 'apex-web/lib/redux/selectors/positionSelectors';
import config from 'apex-web/lib/config';

var mapStateToProps = function mapStateToProps(state, ownProps) {
  var productSymbol = get(ownProps, 'product.ProductSymbol', null);
  var productConfig = config.Deposit[productSymbol] || {};
  var productId = get(ownProps, 'product.ProductId', null);
  var position = positionByProductIdSelector(productId)(state);
  var _state$withdraw = state.withdraw,
      fee = _state$withdraw.withdrawFee,
      TemplateType = _state$withdraw.template.TemplateType;

  var TicketAmount = get(state, ['withdraw', 'TicketAmount'], null);
  var withdrawDetailsInfo = config.Withdraw.WithdrawDetails;
  var isDepositDisabled = ownProps.isDepositDisabled,
      isWithdrawDisabled = ownProps.isWithdrawDisabled;


  return {
    TicketAmount: TicketAmount,
    position: position,
    fee: fee,
    TemplateType: TemplateType,
    productConfig: productConfig,
    withdrawDetailsInfo: withdrawDetailsInfo,
    isDepositDisabled: isDepositDisabled,
    isWithdrawDisabled: isWithdrawDisabled
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    sendToAddress: function sendToAddress(payload) {
      return dispatch(createWithdrawTicket(payload));
    },
    getWithdrawFee: function getWithdrawFee(productId, amount) {
      return dispatch(_getWithdrawFee(productId, amount));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiatSidePaneComponent);