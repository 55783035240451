import ReportBlockTrade from 'apex-web/lib/components/ReportBlockTradeSidePaneComponents/ReportBlockTradeSidePaneContainer';
import ApiKeys from 'apex-web/lib/components/ApiKeysSidePaneComponents/ApiKeysSidePane/ApiKeysSidePaneComponent';
import AdvancedOrder from 'apex-web/lib/components/OrderEntryComponents/AdvancedOrderSidePane/AdvancedOrderSidePaneContainer';
import SendReceive from '../../SendReceiveSidePane/SendReceiveSidePaneContainer';
import KYC_IMForm from 'apex-web/lib/components/KYC_IM/KYC_IMForm';
import KYC_IMLegacyForm from 'apex-web/lib/components/KYC_IMLegacy/KYC_IMLegacyForm';
import KYC_JumioForm from '../../KYC_Jumio/KYC_JumioIframe';
import KYC_ManualForm from 'apex-web/lib/components/KYC_Manual/KYC_ManualForm';
import Reports from 'apex-web/lib/components/ReportSidePaneComponents/ReportSidePaneComponent';
import Deposits from 'apex-web/lib/components/DepositSidePaneComponents/DepositSidePaneComponent';
import Withdraws from '../../WithdrawSidePaneComponents/WithdrawSidePaneComponent';
import RetailFiat from '../../Retail/RetailSidePanes/FiatSidePaneContainer';

export default {
  ReportBlockTrade: ReportBlockTrade,
  ApiKeys: ApiKeys,
  AdvancedOrder: AdvancedOrder,
  SendReceive: SendReceive,
  KYC_IMForm: KYC_IMForm,
  KYC_IMLegacyForm: KYC_IMLegacyForm,
  KYC_JumioForm: KYC_JumioForm,
  KYC_ManualForm: KYC_ManualForm,
  Reports: Reports,
  Deposits: Deposits,
  Withdraws: Withdraws,
  RetailFiat: RetailFiat
};
