import React from 'react';
import PropTypes from 'prop-types';
import APRadio from 'apex-web/lib/components/common/APRadio/APRadio';

var BuySellAmountRow = function BuySellAmountRow(props) {
  var baseClasses = props.baseClasses,
    onClick = props.onClick,
    isActive = props.isActive,
    amountRadio = props.amountRadio,
    value1 = props.value1,
    value2 = props.value2,
    isBuySide = props.isBuySide,
    invertArrows = props.invertArrows;

  var arrow = React.createElement(
    'p',
    { className: baseClasses('arrow') },
    (isBuySide && !invertArrows) || (!isBuySide && invertArrows) ? '→' : '←'
  );

  return React.createElement(
    'div',
    {
      className: baseClasses('amount-row', isActive && 'active'),
      onClick: onClick
    },
    React.createElement(
      'div',
      { className: baseClasses('first-in-row') },
      React.createElement(APRadio, {
        name: 'amountRadio',
        id: amountRadio,
        value: amountRadio
      })
    ),
    React.createElement('div', null, value1 ? value1 : '-'),
    arrow,
    React.createElement(
      'div',
      { className: baseClasses('last-in-row') },
      value2 ? value2 : '-'
    )
  );
};

BuySellAmountRow.propTypes = {
  baseClasses: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  amountRadio: PropTypes.string.isRequired,
  value1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isBuySide: PropTypes.bool.isRequired
};

BuySellAmountRow.defaultProps = {
  isActive: false
};

export default BuySellAmountRow;
