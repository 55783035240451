import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './PurchaseFormPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';

const layoutClasses = getBEMClasses('purchase-form-page');

const PurchaseFormPage = (props, context) => {
  var sto = '';
  var source = {
    url: 'https://www.green-x.io/presale_form_wenew_special',
    title: 'Special Presale Order Form',
    fullUrl: ''
  };

  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();
  }, []);

  // USDT Wallet Balance
  var balanceUSDT = 0;
  if (props.wallet_balance) {
    balanceUSDT = props.wallet_balance['12'].Amount;
  }

  const urlParams = new URLSearchParams(window.location.search);
  sto = urlParams.get('sto');

  // set form url and append params
  let user_info = props.user.userInfo;
  source.fullUrl =
    source.url +
    '?username=' +
    user_info.UserName +
    '&email=' +
    user_info.Email +
    '&balanceUSDT=' +
    balanceUSDT +
    '&language=' +
    localStorage.getItem('language');

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />

      <div className="text-center mt-5">
        <h2>
          {context.t('Your USDT Balance: ')}{' '}
          <span className={balanceUSDT >= 300 ? '' : 'text-danger'}>
            {balanceUSDT} USDT
          </span>
        </h2>

        {balanceUSDT < 300 ? (
          <div className="balanceMsg">
            <p className="text-danger mb-0">
              {context.t('You have insufficient balance.')}
            </p>
            <p className="text-danger mt-0">
              {context.t(
                'Please ensure that you have 300 USDT available in your GreenX Wallet to place order.'
              )}
            </p>
          </div>
        ) : (
          ''
        )}
      </div>

      <h4 className="text-center mt-5">
        {context.t(
          'Note: Please refresh the page if your USDT balance is not updated after you have deposited USDT to your GreenX Wallet.'
        )}
      </h4>
      {/* only for showing iframe without countdown logic */}

      <iframe
        className={
          balanceUSDT >= 300
            ? 'purchase-form-iframe'
            : 'purchase-form-iframe disabled-form'
        }
        src={source.fullUrl}
        title={source.title}
        allow="clipboard-read; clipboard-write"
      />

      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

PurchaseFormPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user,
    wallet_balance:
      state.apexCore.position.positions[state.user.selectedAccountId]
  };
};

var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthentication(PurchaseFormPage));
