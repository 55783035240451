/*
 * @Author: your name
 * @Date: 2021-12-17 10:27:42
 * @LastEditTime: 2021-12-17 13:59:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /v3firstbullion-implementation/src/components/PriceOverview/PriceOverview.js
 */
import React from 'react';

const PriceOverview = () => {
  const tvWidget = `
    <div class="tradingview-widget-container">
  <div id="tradingview_28a7d"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/BTCUSD/?exchange=BITSTAMP" rel="noopener" target="_blank"><span class="blue-text">Bitcoin</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
  <script type="text/javascript">
  new TradingView.MediumWidget(
  {
  "symbols": [
    [
      "Bitcoin",
      "BITSTAMP:BTCUSD|12M"
    ],
    [
      "Ethereum",
      "BITSTAMP:ETHUSD|12M"
    ],
    [
      "Bitcoin Cash",
      "BITSTAMP:BCHUSD|12M"
    ],
    [
      "Litecoin",
      "BITSTAMP:LTCUSD|12M"
    ],
    [
      "XRP",
      "BITSTAMP:XRPUSD|12M"
    ]
  ],
  "chartOnly": false,
  "width": "100%",
  "height": "100%",
  "locale": "en",
  "colorTheme": "dark",
  "gridLineColor": "#2a2e39",
  "trendLineColor": "#e1fa55",
  "fontColor": "#ffffff",
  "underLineColor": "rgba(55, 166, 239, 1)",
  "isTransparent": true,
  "autosize": true,
  "container_id": "tradingview_28a7d"
}
  );
  </script>
</div>`;
  return (
    <iframe
      src={'data:text/html,' + encodeURIComponent(tvWidget)}
      width="100%"
      height="470px"
    />
  );
};

export default PriceOverview;
