import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import { connect } from 'react-redux';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import store from 'apex-web/lib/redux/store';
import { getUserInfo } from 'apex-web/lib/redux/actions/userActions.js';
import { getDepositInfo } from 'apex-web/lib/redux/actions/depositActions.js';
import { openKYC_JumioSidePane } from 'apex-web/lib/redux/actions/sidePaneActions';

import card_vector from '../pages/img/credit-card-image.png';
import { FaInfoCircle } from 'react-icons/fa';

const classes = getBEMClasses('eotc-buy-sell-layout');

// the API url
let Mercuryo_host = 'https://gx.valfx.us/buyw';

let MercuryoPage = (props, context) => {
  useEffect(() => {
    document.title = 'Green-X Credit Card';
  }, []);

  const [wallet, set_wallet_address] = useState('');

  let email = props.user.userInfo.Email,
    id = props.user.userInfo.UserId,
    first_name =
      props.user.userConfig.length > 0
        ? props.user.userConfig.find(e => e.Key == 'GovID_FirstName')
        : null,
    last_name =
      props.user.userConfig.length > 0
        ? props.user.userConfig.find(e => e.Key == 'GovID_LastName')
        : null,
    aff = 'gx',
    user_level = Object.keys(props.user.verificationLevelConfigs).find(
      e => e == 'Level'
    )
      ? props.user.verificationLevelConfigs.Level
      : null,
    // only level 20 & level 0 user cannot buy (this not confirmed , need update)
    can_user_buy =
      user_level == 20 || user_level == 0 || user_level == null ? false : true;

  // console.log(user_level)

  // product id 12 is USDT
  props.getDepositInfo(12).then(res => {
    // return set_wallet_address(res.payload[0]);

    // temp set to false to disabled button
    return set_wallet_address(false);
  });

  // this is the POST payload that need to send
  // {
  //   mode: "live",
  //   name: "Tony Szeto",
  //   email: "tony@firstbullionholdings.com",
  //   id: "client_id",
  //   aff: "gx",
  //   wallet: "wallet_address_for_USDT",
  // }

  return (
    <React.Fragment>
      <div className="mercuryo-credit-card-page">
        <div className={classes('accent-bar')} />
        <div className={classes('container')}>
          <div className={classes('col', ['lg'])}>
            <div className="title-container">
              <div className="col-left">
                {/* <h2>{context.t('Credit Card Purchase')}</h2> */}
              </div>
            </div>
            {/* make POST request  */}
            <div className="credit-card-container">
              <div className="row flex-sm-column flex-md-row">
                <div className="col-lg-6 col-sm-12 bg-col">
                  <img src={card_vector} id="credit_card_vector" />
                </div>
                <div className="col-lg-6 col-sm-12 credit-card-col">
                  <form
                    action={Mercuryo_host}
                    method="POST"
                    name="card_form"
                    id="credit_card_form">
                    <div className="d-flex flex-column justify-content-center align-items-center content-text">
                      <h5 className="unavailable-note">
                        {context.t(
                          'Button Disabled: Service is currently undergoing maintenance.'
                        )}
                      </h5>
                      <h1 style={{ marginTop: '3rem' }}>
                        {context.t('Buy Cryptos with your Credit Card')}
                      </h1>
                      <h3 className="my-4">
                        {context.t(
                          'Wire funds from your bank account to buy crypto assets with your money instantly'
                        )}
                      </h3>
                      <div className="py-4" style={{ marginBottom: '3rem' }}>
                        <FaInfoCircle
                          className="me-2"
                          style={{ fontSize: '1.5rem' }}
                        />
                        {context.t(
                          'Mercuryo applies certain fees for transaction processing, which include:'
                        )}
                        <ul style={{ listStyle: 'disc' }}>
                          <li className="text-white">
                            {context.t(
                              'Network fee is paid to crypto miners for processing blockchain transactions.'
                            )}
                          </li>
                          <li className="text-white">
                            {context.t(
                              'Mercuryo fee covers costs of payment processing and may vary depending on the payment method you choose.'
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <input
                      type="text"
                      name="mode"
                      value="live"
                      readOnly
                      hidden
                    />
                    <input
                      type="text"
                      name="name"
                      defaultValue={
                        first_name && last_name
                          ? first_name.Value + ' ' + last_name.Value
                          : 'null'
                      }
                      readOnly
                      hidden
                    />
                    <input
                      type="text"
                      name="email"
                      defaultValue={email}
                      readOnly
                      hidden
                    />
                    <input
                      type="text"
                      name="id"
                      defaultValue={id}
                      readOnly
                      hidden
                    />
                    <input
                      type="text"
                      name="aff"
                      defaultValue={aff}
                      readOnly
                      hidden
                    />
                    <input
                      type="text"
                      name="wallet"
                      defaultValue={wallet}
                      readOnly
                      hidden
                    />
                    {/* this component is for can_user_buy condition  */}

                    {/* <button type={can_user_buy ? "submit" : "button"}
                      className="ap-button__btn--general"
                      style={{ borderRadius: "40px", marginLeft: "5rem", marginRight: "5rem", paddingTop: "2rem", paddingBottom: "2rem" }}
                      onClick={() => { can_user_buy ? document.card_form.submit() : props.openKYC_JumioSidePane() }}
                    >
                      <h1>{can_user_buy ? "Buy Now" : "Do KYC"}</h1>
                    </button> */}

                    <button
                      type="submit"
                      className="bordered-button-wrapper w-100"
                      disabled={wallet ? false : true}
                      style={{ opacity: '0.5' }}>
                      <div className="bordered-button-content">
                        <h3 className="mb-0">{context.t('Buy Now')}</h3>
                      </div>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes('footer')}>
          <PageFooterComponent />
        </div>
      </div>
    </React.Fragment>
  );
};

MercuryoPage.contextTypes = {
  t: PropTypes.func.isRequired
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user
  };
};
var mapDispatchToProps = {
  getUserInfo,
  getDepositInfo,
  openKYC_JumioSidePane
};

MercuryoPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MercuryoPage);

export default withAuthentication(MercuryoPage);
