import { isValidNumber } from 'libphonenumber-js/custom';
import metadata from 'libphonenumber-js/metadata.min.json';
import { formatDate, formatTime } from './dateHelper';

export var required = function required(value) {
  return value ? undefined : 'Required';
};
// context.t('Required')

export var requiredNumeric = function requiredNumeric(value) {
  return value !== undefined && value !== '' ? undefined : 'Required';
};
// context.t('Required')

export var matchingPassword = function matchingPassword(value, allValues) {
  return value !== allValues.password ? 'Passwords do not match' : undefined;
};
// context.t('Passwords do not match')

export var email = function email(value) {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
};
// context.t('Invalid email address')

export var validateReceiverEmail = function validateReceiverEmail(
  value,
  userEmail
) {
  return value === userEmail
    ? "You can't use your own email as receiver address"
    : undefined;
};
// context.t(`You can't use your own email as receiver address`)

export var noWhiteSpace = function noWhiteSpace(value) {
  return value && /\s/.test(value) ? 'Must not contain whitespace' : undefined;
};
// context.t('Must not contain whitespace')

export var alphanumeric = function alphanumeric(value) {
  return value && !/^[a-z0-9]*$/i.test(value)
    ? 'Input should only contain alphanumeric characters'
    : undefined;
};
// context.t('Input should only contain alphanumeric characters')

export var alphabetic = function alphabetic(value) {
  return value && !/^[a-z]*$/i.test(value)
    ? 'Input should only contain alphabetic characters'
    : undefined;
};
// context.t('Input should only contain alphabetic characters')

export var alphanumericspace = function alphanumericspace(value) {
  return !/^[a-z\d\s]*$/i.test(value)
    ? 'Input should only contain alphanumeric and space characters'
    : undefined;
};
// context.t('Input should only contain alphanumeric and space characters')

export var numeric = function numeric(value) {
  return value && !/^\d*$/i.test(value)
    ? 'Input should only contain numeric characters'
    : undefined;
};
// context.t('Input should only contain numeric characters')

export var biggerThanValue = function biggerThanValue(min) {
  return function(value) {
    return value !== undefined && value <= min
      ? 'Must be bigger than ' + min
      : undefined;
  };
};
/* context.t('Must be bigger than {a}', {
   a: min
}) */

export var biggerThanOrEqualToValue = function biggerThanOrEqualToValue(min) {
  return function(value) {
    return value !== undefined && value !== '' && value < min
      ? 'Must be bigger than or equal to ' + min
      : undefined;
  };
};
/* context.t('Must be bigger than or equal to {a}', {
   a: min
}) */

export var lessThanValue = function lessThanValue(max, value) {
  return value && value > max ? 'Must be less than ' + max : undefined;
};
/* context.t('Must be less than {a}', {
   a: max
}) */

export var biggerThanZero = biggerThanValue(0);

export var biggerThanOrEqualToZero = biggerThanOrEqualToValue(0);

export var validatePassword = function validatePassword(value) {
  return value && !/^(?=.{8,})(?=.*[A-Z])(?=.*[0-9]).*$/g.test(value)
    ? 'Password must contain at least 8 characters, one number, and at least one capital letter'
    : undefined;
};
// context.t('Password must contain at least 8 characters, one number, and at least one capital letter')

export var isDate = function isDate(value) {
  var isInvalid =
    Object.prototype.toString.call(value) === '[object Date]'
      ? isNaN(value.getTime())
      : true;
  return value && isInvalid ? 'Invalid date' : undefined;
};
// context.t('Invalid date')

export var maxLength = function maxLength(max) {
  return function(value) {
    return value && value.length > max
      ? 'Must be ' + max + ' characters or less'
      : undefined;
  };
};
/* context.t('Must be {a} characters or less', {
   a: max
}) */

export var isValidPhone = function isValidPhone(value) {
  return isValidNumber(value, metadata)
    ? undefined
    : 'Must be a valid phone number';
};
// context.t('Must be a valid phone number')

export var maxFileSize = function maxFileSize(value, maxSize) {
  return value && value.file && maxSize <= value.file.size / 1024 / 1024
    ? 'File size must be under ' + maxSize + 'mb'
    : undefined;
};

/* context.t('File size must be under {a}mb', {
   a: maxSize
}) */

export var maxDate = function maxDate(value, _maxDate) {
  return value && value > _maxDate
    ? 'Date must be before ' + formatDate(_maxDate)
    : undefined;
};

/* context.t('Date must be before {a}', {
   a: maxDate
}) */

export var minDate = function minDate(value, _minDate) {
  return value && value < _minDate
    ? 'Date must be after ' + formatDate(_minDate)
    : undefined;
};

/* context.t('Date must be after {a}', {
   a: minDate
}) */

export var minTime = function minTime(value, _minTime) {
  if (!value) return;
  var time = getTimeObj(value);
  var min = getTimeObj(_minTime);

  return time.hours < min.hours ||
    (time.hours === min.hours && time.minutes < min.minutes)
    ? 'Time must be after ' + formatTime(_minTime)
    : undefined;
};

/* context.t('Time must be after {a}', {
   a: maxDate
}) */

export var maxTime = function maxTime(value, _maxTime) {
  if (!value) return;
  var time = getTimeObj(value);
  var max = getTimeObj(_maxTime);

  return time.hours > max.hours ||
    (time.hours === max.hours && time.minutes > max.minutes)
    ? 'Time must be before ' + formatTime(_maxTime)
    : undefined;
};

/* context.t('Time must be before {a}', {
   a: minDate
}) */

var getTimeObj = function getTimeObj(date) {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes()
  };
};
